import { takeEvery, call, put } from 'redux-saga/effects';
import { RESPONSE_STAUS_CODE } from '../../appConstants';
import { GET_AUTHEN_TOKEN, getAuthenTokenSuccess } from '../actions/OnBroadAction';
import { authenToken } from '../../api/OnboardSevices';
import { GetAuthenTokenAction, Response } from '../../types/OnboardTypes';

function* fetchAuthenToken(action: GetAuthenTokenAction) { 
    try {
        const res:Response = yield call(authenToken, action.payload);

        if (res?.status === RESPONSE_STAUS_CODE.SUCCESS.code && res?.data) {
            yield put(getAuthenTokenSuccess(res?.data));
        } 
    } catch (error) {
        console.log(error);
    }
}

export default function* OnboardSaga() {
    yield takeEvery(GET_AUTHEN_TOKEN, fetchAuthenToken);
}
