// Tabs.tsx
import React, { useState, useEffect } from "react";
import { TabProps, TabsProps } from "../../types/Tab";

const Tab: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>;
};

const Tabs: React.FC<TabsProps> = ({ children, storageKey = "activeTab" }) => {
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem(storageKey);
    return savedTab ? parseInt(savedTab, 10) : 0;
  });

  useEffect(() => {
    localStorage.setItem(storageKey, activeTab.toString());
  }, [activeTab, storageKey]);

  useEffect(() => {
    const handleUnload = () => {
      localStorage.removeItem(storageKey);
    };

    window.addEventListener("beforeunload", handleUnload);
    // Trên mobile, sử dụng sự kiện "pagehide" để đảm bảo trạng thái cũng được xóa
    window.addEventListener("pagehide", handleUnload);

    // Cleanup event listeners khi component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      window.removeEventListener("pagehide", handleUnload);
    };
  }, [storageKey]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="w-full">
      {/* Tab Buttons */}
      <div className="flex item-center justify-center px-3">
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) return null;
          return (
            <button
              className={`py-3 px-2.5 w-1/2 font-bold  ${
                index === activeTab
                  ? "text-white rounded-xl bg-green500"
                  : "text-gray"
              }`}
              onClick={() => handleTabClick(index)}
            >
              {child.props.label}
            </button>
          );
        })}
      </div>

      {/* Tab Content */}
      <div className="py-4">{React.Children.toArray(children)[activeTab]}</div>
    </div>
  );
};

export { Tabs, Tab };
