import { Error, InformationMerchant, InformationMerchantFailureResponse, InformationMerchantPayload } from "../../types/StoreInfor";

export const GET_INFORMATION_MERCHANT = "GET_INFORMATION_MERCHANT";
export const GET_INFORMATION_MERCHANT_SUCCESS = "GET_INFORMATION_MERCHANT_SUCCESS";
export const GET_INFORMATION_MERCHANT_FAIL = "GET_INFORMATION_MERCHANT_FAIL";

export const UPDTAE_INFORMATION_MERCHANT = "UPDTAE_INFORMATION_MERCHANT";
export const UPDTAE_INFORMATION_MERCHANT_SUCCESS = "UPDTAE_INFORMATION_MERCHANT_SUCCESS";
export const UPDTAE_INFORMATION_MERCHANT_FAIL = "UPDTAE_INFORMATION_MERCHANT_FAIL";

export const getInformationMerchant = () => {    
    return {
        type: GET_INFORMATION_MERCHANT,
    }
}
export const getInformationMerchantSuccess = (payload: InformationMerchant) => {
    return {
        type: GET_INFORMATION_MERCHANT_SUCCESS,
        payload
    }
}
export const getInformationMerchantFail = (payload: Error) => {
    return {
        type: GET_INFORMATION_MERCHANT_FAIL,
        payload
    }
}

export const updateInformationMerchant = (payload: InformationMerchantPayload) => {
    return {
        type: UPDTAE_INFORMATION_MERCHANT,
        payload
    }
}
export const updateInformationMerchantSuccess = (payload: InformationMerchant) => {
    return {
        type: UPDTAE_INFORMATION_MERCHANT_SUCCESS,
        payload
    }
}
export const updateInformationMerchantFail = (payload: InformationMerchantFailureResponse) => {
    return {
        type: UPDTAE_INFORMATION_MERCHANT_FAIL,
        payload
    }
}
