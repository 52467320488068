// import axios from "axios";
import { QuickReportSearchObject } from "../types/HomeTypes";
import api from "./AxiosInterceptor";
const config = require("../appConfig");
const patchApi = config.API_ENPOINT + "/report/v1.0";

export const getQuickReportApi = async (searchObject: QuickReportSearchObject) => {   
    let url = `${patchApi}/quick-report`;
    if (searchObject) {
        const response = await api.get(url, {params: searchObject});
        return response.data; 
    }
}
