import { GetListActionTypes, ListCustomerRes } from "../../../types/Customer";
import {
  GET_LIST_CUSTOMER_FAIL,
  GET_LIST_CUSTOMER_SUCCESS,
} from "../../actions/Customer/CustomerAction";

interface CustomerState {
  dataListCustomer: ListCustomerRes;
}

const initialState: CustomerState = {
  dataListCustomer: {
    result: [],
    size: 0,
  },
};

const CustomerReducer = (
  state = initialState,
  action: GetListActionTypes
): CustomerState => {
  switch (action.type) {
    case GET_LIST_CUSTOMER_SUCCESS:
      return {
        ...state,
        dataListCustomer: {
          result: state.dataListCustomer.result.concat(action.payload.result),
          size: action.payload.size,
        },
      };
    case GET_LIST_CUSTOMER_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default CustomerReducer;
