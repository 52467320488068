import { all } from "redux-saga/effects";
import OnboardSaga from "./OnboardSaga";
import StoreSetupSaga from "./StoreSetupSaga";
import CustomerSaga from "./Customer/CustomerSaga";
import HomePageSaga from "./HomePageSaga";
import ListTransactionSaga from "./TransactionSaga/ListTransactionSaga";
import ListGroupSaga from "./Customer/ListGroupSaga";
import ReportgeSaga from "./ReportSaga";
import CustomerReportSaga from "./Customer/CustomerReportSaga";

export function* rootSaga() {
  yield all([
    OnboardSaga(),
    StoreSetupSaga(),
    CustomerSaga(),
    ListGroupSaga(),
    ListTransactionSaga(),
    HomePageSaga(),
    ReportgeSaga(),
    CustomerReportSaga(),
  ]);
}
