import React from "react";

import { useNavigate } from "react-router-dom";
import CustomDropdown from "../FilterSelect/FilterSelect";
import { DATE_SELECTOR_TRANSACTION } from "../../appConstants";
import { convertNumber } from "../../appCommon";
import { TransactionProps } from "../../types/Transaction";
import ItemTransaction from "../ItemCustomer/ItemTransaction";

interface TransactionProp {
  onSelectValue: (value: string) => void;
  transactions: TransactionProps[];
}

const ListTransaction: React.FC<TransactionProp> = ({
  onSelectValue,
  transactions,
}) => {
  const navigate = useNavigate();
  const isTransactions = Array.isArray(transactions) && transactions.length > 0;
  
  return (
    <div className="p-4 shadow-custom bg-white rounded-2xl">
      <div className="flex justify-between items-center mb-6">
        <p className="text-xl font-extrabold">Tất cả giao dịch</p>
        <CustomDropdown
          valueSelect={(value: string) => onSelectValue(value)}
          minDay={false}
          isSelectTime={true}
          filterOption={DATE_SELECTOR_TRANSACTION}
        />
      </div>
      {isTransactions 
       ? (
        <>
          {transactions.map((transaction) => (
            <div
              key={transaction.statementId}
              onClick={() =>
                navigate("/detail-transaction-page", {
                  state: {
                    statementId: transaction.statementId,
                    name: transaction.contractCustomerName,
                    stk: transaction.contraAccountNbr,
                    bankName: transaction.contractBank,
                    content: transaction.note,
                    sign: transaction.sign,
                    money: convertNumber(transaction.amount),
                    time: transaction.txnTime,
                  },
                })
              }
            >
              <ItemTransaction
                name={transaction.contractCustomerName}
                nickName={transaction.nickname}
                stk={transaction.contraAccountNbr}
                isNickName={true}
                isIcons={true}
                moveAway={transaction.sign}
                money={convertNumber(transaction.amount)}
                time={transaction.txnTime}
              />
            </div>
          ))}
        </>
       ) : (<>
        <div className="flex w-full justify-center text-gray500 font-medium leading-5">Chưa có giao dịch nào</div>
       </>)
      }
    </div>
  );
};

export default ListTransaction;
