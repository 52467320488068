import React, { useCallback, useEffect, useState } from "react";
import { Edit, Payment, LeftWhite, PhoneGray } from "../../assets/Icons";
import { DATE_SELECTOR, DEFAULT_TIME_REPORT, TODAY } from "../../appConstants";

import { LogoTech } from "../../assets/Images/index";

import { useLocation, useNavigate } from "react-router-dom";
import CustomDropdown from "../../components/FilterSelect/FilterSelect";
import CustomChart from "../../components/chart/ColumnsChart/CustomChart";
import { convertNumber, formatDateReport } from "../../appCommon";

import { useDispatch, useSelector } from "react-redux";

import LoadingPage from "../../components/Loading/Loading";
import { subMonths } from "date-fns";
import { listTransactionSelector } from "../../redux/selector/TransactionSelector/ListTransactionSeletor";
import { getListTransaction } from "../../redux/actions/Transaction/ListTransactionAction";
import { DateProps, TransactionProps } from "../../types/Transaction";
import { customerReportSelector } from "../../redux/selector/Customer/CustomerReportSelector";
import { getCustomerReport } from "../../redux/actions/Customer/CustomerReportAction";
import ItemTransaction from "../../components/ItemCustomer/ItemTransaction";
import { formatDateParams, formatDateTime } from "../../common/date";
import { LoadingSelector } from "../../redux/selector/LoadingSelector";

const DetailCustomer: React.FC = () => {
  const [selectDate, setSelectDate] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { accountCustomer, name, stk, group, phone, nickName, bank, income } =
    location.state || {};
  const dataListCustomerTransaction =
    useSelector(listTransactionSelector) || {};

  const dataCustomerReport = useSelector(customerReportSelector);
  const isLoading = useSelector(LoadingSelector);
  const fetchCustomerTransaction = useCallback(() => {
    const today = formatDateParams(new Date());
    const fiveMonths = formatDateParams(subMonths(new Date(), 5));
    const searchObject = {
      customerAccount: "9666699998",
      contractBank: "mb",
      contractAccountNbr: "111111111",
      contractCustomerName: "nguyen ba tung lam",
      fromDate: fiveMonths,
      toDate: today,
      skip: 1,
      limit: 5,
      transactionType: "all",
      extraction: "Y",
    };
    dispatch(getListTransaction(searchObject));
  }, [accountCustomer]);

  const fetchCustomerReport = () => {
    const fromToDate: DateProps = formatDateReport(
      selectDate,
      DEFAULT_TIME_REPORT
    );
    const searchObject = {
      customerAccount: "9666699998",
      contractBank: "mb",
      contractAccountNbr: "111111111",
      contractCustomerName: "nguyen ba tung lam",
      fromDate: fromToDate.fromDate,
      toDate: fromToDate.toDate,
      skip: 1,
    };
    dispatch(getCustomerReport(searchObject));
  };
  useEffect(() => {
    fetchCustomerTransaction();
    fetchCustomerReport();
  }, [accountCustomer, selectDate]);
  return (
    <>
      <div className="bg-green500">
        <header className="flex justify-start items-center mx-6 h-[100px] ">
          <div
            className="px-2 flex justify-center items-center "
            onClick={() => {
              navigate("/customer");
            }}
          >
            <LeftWhite />
          </div>
          <div className="text-xl text-white font-extrabold flex justify-center content-center text-center w-full mr-9">
            Chi tiết khách
          </div>
        </header>
        <main className="bg-white rounded-tl-[32px] rounded-tr-[32px]">
          <div className="px-6 pt-[24px] mb-4">
            <div className="bg-green200 text-green500 rounded w-fit p-1 mb-[4px] font-bold">
              {group}
            </div>
            <div className="flex justify-between items-center mb-2">
              <div className="flex gap-2 justify-between items-center">
                <p className="text-xl font-extrabold">{name}</p>
                <p className="text-base font-extrabold text-gray">
                  {nickName && `(${nickName})`}
                </p>
              </div>
              <p
                onClick={() => {
                  navigate("/info-customer");
                }}
              >
                <Edit />
              </p>
            </div>
            <p className="flex items-center gap-2 text-gray">
              <PhoneGray /> {phone}
            </p>
          </div>
          <div className="px-6">
            <div className="bg-green200 rounded-2xl p-4 mb-[8px]">
              <p>Tổng doanh thu</p>
              <p className="text-lg font-bold">
                {" "}
                {convertNumber(income)}
                <span className="text-lg font-bold">đ</span>
              </p>
            </div>
          </div>
          <div className="px-6">
            <div className="flex gap-[8px] text-xs font-semibold text-gray500 p-3">
              <Payment /> Đơn hàng đặt gần đây
            </div>
          </div>
          <div className="">
            <div className="flex justify-between items-center px-6">
              <p className="text-lg font-extrabold">Tài khoản ngân hàng</p>
              {/* <p className="text-green500 font-semibold">Thêm tài khoản</p> */}
            </div>
            <div className="pl-6">
              <div className="flex flex-row gap-[12px] overflow-x-auto mb-[24px]">
                {/* {customers.map((item) => ( */}
                <div className="py-[8px] px-[12px] rounded-2xl border border-[#F0F1F5]">
                  <div className="flex gap-2 items-start">
                    <div className="flex justify-center items-start">
                      <img src={LogoTech} alt="bank" />
                    </div>
                    <div className="flex justify-between item-start border-b border-gray100">
                      <div>
                        <p className="font-bold text-nowrap">{name}</p>
                        <p className="text-gray text-nowrap">
                          STK:
                          {stk}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="px-6">
            <p className="text-lg font-extrabold mb-[16px]">
              Báo cáo dòng tiền
            </p>
            <div className="flex justify-between items-start">
              <div>
                <p>Tổng doanh thu</p>
                <p className="flex gap-1 items-start ">
                  <span className="font-bold">đ</span>

                  <span className="text-2xl font-extrabold">
                    {convertNumber(dataCustomerReport.totalIncome)}
                  </span>
                </p>
              </div>
              <CustomDropdown
                valueSelect={(value: string) => setSelectDate(value)}
                minDay={false}
                isSelectTime={false}
                filterOption={DATE_SELECTOR}
              />
            </div>
            <div className="w-full overflow-x-auto scrollbar scrollbar-thumb-white">
              <CustomChart
                customerReport={dataCustomerReport.incomes}
                type={dataCustomerReport.type}
              />
            </div>
          </section>
          <section className="px-3">
            <div className="shadow-custom p-4 rounded-2xl">
              <div className="flex justify-between items-center mb-[24px]">
                <p className="text-lg font-extrabold">Tất cả giao dịch</p>
                <p
                  className="text-green500"
                  onClick={() => {
                    navigate("/detail-transaction", {
                      state: {
                        customerAccount: "9666699998",
                        contractBank: "mb",
                        contractCustomerName: "nguyen ba tung lam",
                        contractAccountNbr: "111111111",
                      },
                    });
                  }}
                >
                  Xem tất cả
                </p>
              </div>
              <div>
                {dataListCustomerTransaction.map(
                  (transaction: TransactionProps) => (
                    <div
                      key={transaction.statementId}
                      onClick={() =>
                        navigate("/detail-transaction-page", {
                          state: {
                            statementId: transaction.statementId,
                            name: transaction.contractCustomerName,
                            stk: transaction.contraAccountNbr,
                            bankName: transaction.contractBank,
                            content: transaction.note,
                            sign: transaction.sign,
                            money: convertNumber(transaction.amount),
                            time: formatDateTime(transaction.txnTime),
                          },
                        })
                      }
                    >
                      <ItemTransaction
                        name={transaction.contractCustomerName}
                        nickName={transaction.nickname}
                        stk={transaction.contraAccountNbr}
                        isNickName={false}
                        isIcons={true}
                        moveAway={transaction.sign}
                        money={convertNumber(transaction.amount)}
                        time={transaction.txnTime}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </section>
        </main>
      </div>
      <LoadingPage isLoading={isLoading} />
    </>
  );
};

export default DetailCustomer;
