import { takeEvery, call, put } from "redux-saga/effects";
import { setLoading } from "../../actions/LoadingAction";
import {
  GET_REPORT_CUSTOMER,
  getCustomerReportFail,
  getCustomerReportSuccess,
} from "../../actions/Customer/CustomerReportAction";
import { getReportCustomer } from "../../../api/Customer";

interface Respon {
  code?: number;
  data?: any;
  message?: string;
}

function* fetchCustomerReportGroup(action: any) {
  try {
    // Bật loading khi bắt đầu gọi API
    yield put(setLoading(true));

    // Gọi API
    const res: Respon = yield call(getReportCustomer, action.payload);

    // Thành công, lưu dữ liệu vào Redux
    yield put(getCustomerReportSuccess(res?.data));
  } catch (error) {
    // Gặp lỗi, cập nhật lỗi vào Redux
    yield put(getCustomerReportFail(error));
  } finally {
    // Tắt loading khi kết thúc gọi API
    yield put(setLoading(false));
  }
}

export default function* CustomerReportSaga() {
  yield takeEvery(GET_REPORT_CUSTOMER, fetchCustomerReportGroup);
}
