export const GET_REPORT_CUSTOMER = "GET_REPORT_CUSTOMER";
export const GET_REPORT_CUSTOMER_SUCCESS = "GET_REPORT_CUSTOMER_SUCCESS";
export const GET_REPORT_CUSTOMER_FAIL = "GET_REPORT_CUSTOMER_FAIL";

export const getCustomerReport = (payload: object) => {
  return {
    type: GET_REPORT_CUSTOMER,
    payload,
  };
};
export const getCustomerReportSuccess = (payload: any) => {
  return {
    type: GET_REPORT_CUSTOMER_SUCCESS,
    payload,
  };
};
export const getCustomerReportFail = (payload: any) => {
  return {
    type: GET_REPORT_CUSTOMER_FAIL,
    payload,
  };
};
