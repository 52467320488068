import { combineReducers } from "redux";
import StoreSetupReducer from "./StoreSetupReducer";
import HomePageReducer from "./HomePageReducer";
import ListGroupReducer from "./Customer/ListGroupReducer";
import ListTransactionReducer from "./TransactionReducers/ListTransactionReducer";
import ReportReducer from "./ReportReducer";
import OnboardReducer from "./OnboardReducer";
import CustomerReducer from "./Customer/CustomerReducer";
import loadingReducer from "./LoadingReducer";
import getCustomerReportReducer from "./Customer/CustomerReportReducer";

const RootReducer = combineReducers({
  storeSetup: StoreSetupReducer,
  customer: CustomerReducer,
  listGroup: ListGroupReducer,
  customerReport: getCustomerReportReducer,
  listTransaction: ListTransactionReducer,
  homePage: HomePageReducer,
  report: ReportReducer,
  onboard: OnboardReducer,
  loading: loadingReducer,
});

export default RootReducer;
