import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_LIST_CUSTOMER,
  // getListCustomerFail,
  getListCustomerSuccess,
} from "../../actions/Customer/CustomerAction";
import { getListCustomer } from "../../../api/Customer";
import { setLoading } from "../../actions/LoadingAction";
import { GetListCustomerAction, ResponseData } from "../../../types/Customer";

function* fetchListCustomer(action: GetListCustomerAction) {
  try {
    // Bật loading khi bắt đầu gọi API
    yield put(setLoading(true));

    // Gọi API
    const res: ResponseData = yield call(getListCustomer, action.payload);
    // Thành công, lưu dữ liệu vào Redux
    yield put(getListCustomerSuccess(res?.data));
  } catch (error) {
    // Gặp lỗi, cập nhật lỗi vào Redux
    // yield put(getListCustomerFail(detail));
    console.log(error);
  } finally {
    // Tắt loading khi kết thúc gọi API
    yield put(setLoading(false));
  }
}

export default function* CustomerSaga() {
  yield takeEvery(GET_LIST_CUSTOMER, fetchListCustomer);
}
