import axios from "axios";
import { AuthTokenPayload } from "../types/OnboardTypes";
const config = require("../appConfig");
const patchApi = config.API_ENPOINT + "/auth/v1.0";

const refreshApi = axios.create({
  baseURL: patchApi,
});

export const authenToken = async (token: AuthTokenPayload) => {
  const url = `${patchApi}/acceptToken`;
  const response = await refreshApi.post(url, token);

  if (response && response.data && response.data.data.accessToken) {
    const accessToken = response.data.data.accessToken;
    const refreshToken = response.data.data.refreshToken;

    // Lưu accessToken và refreshToken vào localStorage
    localStorage.setItem("accessToken", JSON.stringify(accessToken));
    localStorage.setItem("refreshToken", JSON.stringify(refreshToken));

    axios.interceptors.request.use(
      (config) => {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  return response;
};
