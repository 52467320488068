import React from "react";
import { useNavigate } from "react-router-dom";

import { Tab, Tabs } from "../../components/Tabs/Tabs";
import Transaction from "../../components/Transaction/Transaction";
import Statement from "../../components/Statement/Statement";
import { LeftBlack } from "../../assets/Icons";

const TransactionDetails: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <header className="flex justify-start items-center mx-6 mb-4 mt-3 ">
        <div
          className="px-2 flex justify-center items-center "
          onClick={() => {
            navigate("/detail-customer");
          }}
        >
          <LeftBlack />
        </div>
        <div className="text-xl font-extrabold flex justify-center content-center text-center w-full mr-4">
          Chi tiết giao dịch
        </div>
      </header>
      <div className="p-3">
        <Tabs>
          <Tab label="Giao dịch">
            <div>
              <Transaction />
            </div>
          </Tab>
          <Tab label="Sao kê thông minh">
            <div>
              <Statement />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default TransactionDetails;
