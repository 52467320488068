import { format } from "date-fns";

export const CustomizedXAxisTick: React.FC<{
  x?: number;
  y?: number;
  payload: { value: string, index: number };
  highlightedValue: string | null;
  isMonth: boolean
  setHighlightedValue: (value: string) => void;
}> = ({ x = 0, y = 0, payload, highlightedValue, setHighlightedValue, isMonth }) => {
  const isActive = highlightedValue === payload.value;

  const displayValue =  isMonth 
  ? `T ${format(payload.value, 'MM')}` 
  : format(payload.value, 'dd/MM'); 
  
  return (
    <g onClick={() => setHighlightedValue(payload.value)}>
      <text
        x={x}
        y={y}
        fill={isActive ? '#06BD7D' : '#8B929C'} // Đổi màu nếu đã được chọn
        textAnchor="middle"
        dy={10}
        style={{ cursor: 'pointer' }}
      >
        {displayValue}
      </text>
    </g>
  );
};