import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import ItemCustomer from "../../components/ItemCustomer/ItemCustomer";
import { LeftBlack } from "../../assets/Icons";

import { UserIcon } from "../../assets/Icons/IconFooterSVG";
import { useDispatch, useSelector } from "react-redux";
import { listCustomerSelector } from "../../redux/selector/Customer/CustomerSelector";
import { getListCustomer } from "../../redux/actions/Customer/CustomerAction";

import { CustomerProp, SearchParams } from "../../types/Customer";
import LoadingPage from "../../components/Loading/Loading";
import { LoadingSelector } from "../../redux/selector/LoadingSelector";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";

const DetailGroupCustomer: React.FC = () => {
  const [page, setPage] = useState(1);

  const location = useLocation();
  const { name } = location.state || "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listCustomer = useSelector(listCustomerSelector) || [];
  const isLoading = useSelector(LoadingSelector);
  const fetchListCustomer = () => {
    const searchObject: SearchParams = {
      skip: page,
      limit: 10,
      customer_group: name,
      searchInput: "",
    }; // Pass the current page

    dispatch(getListCustomer(searchObject));
  };
  useInfiniteScroll({
    isLoading,
    onLoadMore: () => setPage((prevPage) => prevPage + 1),
    offset: 100, // Optional: customize the offset
  });
  useEffect(() => {
    fetchListCustomer();
  }, [page, name]);
  return (
    <>
      <header className="flex justify-start items-center mx-6 h-[60px] ">
        <div
          className="px-2 flex justify-center items-center "
          onClick={() => {
            navigate("/customer");
          }}
        >
          <LeftBlack />
        </div>
        <div className="text-xl font-extrabold flex justify-center content-center text-center w-full mr-9">
          Chi tiết nhóm khách
        </div>
      </header>
      <main className="bg-white">
        <div className="rounded-2xl p-4 bg-green200 m-[12px] mb-4">
          <p className="flex justify-start items-center gap-3  text-base font-semibold">
            <UserIcon isActive={true} />
            {name}
          </p>
        </div>
        <section className="shadow-custom  mx-3">
          <div className="p-4 rounded-2xl">
            <div className="mb-6">
              <p className="text-lg text-base font-bold">Tất cả khách hàng</p>
              <span className="text-gray">{listCustomer?.size} khách hàng</span>
            </div>
            <div>
              {listCustomer?.result?.map((customer: CustomerProp) => (
                <ItemCustomer
                  key={customer.customerId}
                  name={customer.customerName}
                  nickName={customer.customerNickname}
                  stk={customer.customerAccountNbr}
                  group={customer.customerGroup}
                  isNickName={true}
                  isIcons={false}
                />
              ))}
            </div>
          </div>
        </section>
      </main>
      <LoadingPage isLoading={isLoading} />
    </>
  );
};

export default DetailGroupCustomer;
