import { takeEvery, call, put } from "redux-saga/effects";
// import { RESPONSE_STAUS_CODE } from '../../../appConstants';
import {
  GET_LIST_TRANSACTION,
  getListTransactionFail,
  getListTransactionSuccess,
} from "../../actions/Transaction/ListTransactionAction";
import { getListTranscation } from "../../../api/Transaction";
import { setLoading } from "../../actions/LoadingAction";

interface Respon {
  code?: number;
  data?: any;
  message?: string;
}

function* fetchListTransaction(action: any) {
  // try {
  //   const res: Respon = yield call(getListTranscation, action.payload);
  //   if (true) {
  //     yield put(getListTransactionSuccess(res?.data));
  //   } else {
  //     yield put(getListTransactionFail(res?.message));
  //     // console.log(res?.message)
  //   }
  // } catch (error) {
  //   // console.log(error)
  // }

  try {
    // Bật loading khi bắt đầu gọi API
    yield put(setLoading(true));

    // Gọi API
    const res: Respon = yield call(getListTranscation, action.payload);

    // Thành công, lưu dữ liệu vào Redux
    yield put(getListTransactionSuccess(res?.data));
  } catch (error) {
    // Gặp lỗi, cập nhật lỗi vào Redux
    yield put(getListTransactionFail(error));
  } finally {
    // Tắt loading khi kết thúc gọi API
    yield put(setLoading(false));
  }
}

export default function* ListTransactionSaga() {
  yield takeEvery(GET_LIST_TRANSACTION, fetchListTransaction);
}
