import React from "react";
import LogoBank from "./../../assets/Images/image11.png";
import { StatementProp } from "../../types/Transaction";
import { UpRight, ArrowGreen, ArrowRed } from "../../assets/Icons";
import { formatDateTime } from "../../common/date";
const FromStatement: React.FC<StatementProp> = ({
  moveAway,
  name,
  stk,
  MFT,
  time,
  content,
  sdt,
  money,
  isHidden,
}) => {
  return (
    <div className="rounded-2xl border border-[#F0F1F5] mb-4">
      <div className="flex justify-between items-start border-b border-gray100 p-4 mb-4">
        <div>
          <p className="text-base font-bold text-nowrap">{name}</p>
          <p className="flex justify-start items-center text-gray text-nowrap gap-1">
            {moveAway === "D" ? <ArrowRed /> : <ArrowGreen />}STK:{stk}
          </p>
        </div>
        <div className="text-end">
          <p className="flex justify-end items-center text-xs text-green500 font-semibold">
            Mã FT: {MFT} <UpRight />
          </p>
          <p className="text-xs text-[#A2A0A8]">{formatDateTime(time)}</p>
        </div>
      </div>
      <div className="px-4 border-b border-[#F0F1F5]">
        <div className="mb-4 flex justify-between items-center">
          <p>Ngân hàng</p>
          <p className="flex justify-center items-center gap-[6px] font-semibold">
            {isHidden ? <img src={LogoBank} alt="" /> : null} TechcomBank
          </p>
        </div>
        <div className="mb-4 flex justify-between items-center">
          <p>STK</p>
          <p className=" font-semibold">{stk}</p>
        </div>
        <div className="mb-4 flex justify-between items-start">
          <p>Nội Dung</p>
          <p className="max-w-[200px] line-clamp-2 text-end font-semibold">
            {content}
          </p>
        </div>
        {!isHidden ? (
          <div className="mb-4 flex justify-between items-start">
            <p>Mã đơn hàng</p>
            <p className=" max-w-[200px] line-clamp-2 text-end font-semibold">
              {content}
            </p>
          </div>
        ) : null}
      </div>
      <div className="flex justify-between items-center p-4 font-semibold">
        <p>SDT: {sdt}</p>
        <div className="text-end font-semibold">
          {moveAway === "D" ? "-" : "+"} {money}
        </div>
      </div>
    </div>
  );
};

export default FromStatement;
