import React from "react";
import FromStatement from "../FromStatement/FromStatement";
import CustomDropdown from "../FilterSelect/FilterSelect";
import { DATE_SELECTOR_TRANSACTION } from "../../appConstants";
import { convertNumber } from "../../appCommon";
import { TransactionProps } from "../../types/Transaction";

interface StatementProp {
  onSelectValue: (value: string) => void;
  transactions: TransactionProps[];
}

const ListStatement: React.FC<StatementProp> = ({
  onSelectValue,
  transactions,
}) => {
  return (
    <div className="py-4 px-3 bg-white rounded-2xl">
      <div className="flex justify-between items-center mb-4">
        <p className="text-xl font-extrabold">Tất cả sao kê</p>
        <CustomDropdown
          valueSelect={(value: string) => onSelectValue(value)}
          minDay={true}
          isSelectTime={true}
          filterOption={DATE_SELECTOR_TRANSACTION}
        />
      </div>
      <div>
        {transactions?.map((transaction) => (
          <FromStatement
            key={transaction.statementId}
            moveAway={transaction.sign}
            name={transaction.contractCustomerName}
            stk={transaction.contraAccountNbr}
            MFT={transaction.statementId}
            time={transaction.txnTime}
            content={transaction.note}
            sdt={transaction.entityExtraction?.phone[0]}
            money={convertNumber(transaction.amount)}
            isHidden={false}
          />
        ))}
      </div>
    </div>
  );
};

export default ListStatement;
