import { ReportAction, TopCustomerAction } from '../types/ReportType';
import api from './AxiosInterceptor';
const config = require('../appConfig');
const patchApi = config.API_ENPOINT + "/report/v1.0";
const patchApiCustomer = config.API_ENPOINT + "/customer/v1.0";

export const getReport = async (params: ReportAction) => {
    const url = patchApi + "/pnl-report";
    const response = await api.get(url, {params});
    
   return response.data; 
}

export const getTopCustomer = async (params: TopCustomerAction) => {
    const url = patchApiCustomer + "/list-customer";
    const response = await api.get(url, {params});

    return response.data;
}
