import { ErrorMessage, StoreInfo } from "../../types/StoreInfor";

  
  export const validateStoreInfo = (
    values: StoreInfo,
    setErrorMessage: React.Dispatch<React.SetStateAction<ErrorMessage>>
  ) => {
    const errorState: ErrorMessage = {
      errorStoreName: "",
      errorStoreAddress: "",
      errorStorePhone: "",
    };
  
    let hasError = false; 
  
    if (!values.name) {
      errorState.errorStoreName = "Tên cửa hàng là giá trị bắt buộc";
      hasError = true; 
    }
  
    if (!values.address) {
      errorState.errorStoreAddress = "Địa chỉ là giá trị bắt buộc";
      hasError = true; 
    }
  
    if (!values.phone) {
      errorState.errorStorePhone = "Số điện thoại là giá trị bắt buộc";
      hasError = true; 
    } else if (!/^(0\d{9,11})$/.test(values.phone)) {
      errorState.errorStorePhone = "Số điện thoại bắt đầu bằng 0 có tối thiểu 9 và đa 12 ký tự";
      hasError = true; 
    }
  
    // Cập nhật trạng thái lỗi nếu có lỗi
    setErrorMessage(errorState);
  
    return hasError;
  };