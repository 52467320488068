import { format } from "date-fns";
import React, { useEffect } from "react";
import { useCallback, useState } from "react";
import Picker, { PickerValue } from "react-mobile-picker";

function getDayArray(year: number, month: number) {
  const dayCount = new Date(year, month, 0).getDate();
  return Array.from({ length: dayCount }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
}
interface DatePickerProps {
  onNext?: () => void;
  isFinal: boolean;
  isDisabled: boolean;
  onSearch?: () => void;
  onPrev: () => void;
  onValue: (value: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onNext,
  onSearch,
  onPrev,
  onValue,
  isDisabled,
  isFinal,
}) => {
  const [pickerValue, setPickerValue] = useState<PickerValue>({
    year: new Date().getFullYear().toString(),
    month: (new Date().getMonth() + 1).toString(),
    day: new Date().getDate().toString(),
  });

  useEffect(() => {
    const { day, month, year } = pickerValue;
    const date = new Date(Number(year), Number(month) - 1, Number(day)); // Tạo đối tượng Date
    const formatted = format(date, "dd/MM/yyyy");
    onValue(formatted.toString());
  }, [pickerValue]);

  const handlePickerChange = useCallback(
    (newValue: PickerValue, key: string) => {
      if (key === "day") {
        setPickerValue(newValue);
        return;
      }

      const { year, month } = newValue;
      const newDayArray = getDayArray(Number(year), Number(month));
      const newDay = newDayArray.includes(newValue.day as string)
        ? newValue.day
        : newDayArray[newDayArray.length - 1];
      setPickerValue({ ...newValue, day: newDay });
    },
    []
  );

  return (
    <>
      <div className="mb-4">
        <Picker
          value={pickerValue}
          onChange={handlePickerChange}
          wheelMode="natural"
        >
          <Picker.Column name="day">
            {getDayArray(
              Number(pickerValue.year),
              Number(pickerValue.month)
            ).map((day) => (
              <Picker.Item key={day} value={day}>
                {({ selected }) => (
                  <div
                    className={
                      selected
                        ? "text-base font-bold"
                        : "text-base  text-[#C2C8D1]"
                    }
                  >
                    {day}
                  </div>
                )}
              </Picker.Item>
            ))}
          </Picker.Column>
          <Picker.Column name="month">
            {Array.from({ length: 12 }, (_, i) =>
              String(i + 1).padStart(2, "0")
            ).map((month) => (
              <Picker.Item key={month} value={month}>
                {({ selected }) => (
                  <div
                    className={
                      selected
                        ? "text-base font-bold"
                        : "text-base  text-[#C2C8D1]"
                    }
                  >
                    {month}
                  </div>
                )}
              </Picker.Item>
            ))}
          </Picker.Column>
          <Picker.Column name="year">
            {Array.from({ length: 200 }, (_, i) => `${1923 + i}`).map(
              (year) => (
                <Picker.Item key={year} value={year}>
                  {({ selected }) => (
                    <div
                      className={
                        selected
                          ? "text-base font-bold"
                          : "text-base  text-[#C2C8D1]"
                      }
                    >
                      {year}
                    </div>
                  )}
                </Picker.Item>
              )
            )}
          </Picker.Column>
        </Picker>
      </div>
      <div className="flex item-center justify-center gap-[12px] w-full">
        <button
          type="button"
          className="py-[16px] px-[10px] bg-green200 text-green500 font-bold rounded-2xl w-full"
          onClick={onPrev}
        >
          Quay lại
        </button>
        {!isFinal ? (
          <button
            type="button"
            className="py-[16px] px-[10px] bg-green500 text-white font-bold rounded-2xl w-full"
            disabled={isDisabled}
            onClick={onSearch}
          >
            Lọc kết quả
          </button>
        ) : (
          <button
            type="button"
            className="py-[16px] px-[10px] bg-green500 text-white font-bold rounded-2xl w-full"
            onClick={onNext}
          >
            Tiếp tục
          </button>
        )}
      </div>
    </>
  );
};

export default DatePicker;
