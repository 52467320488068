import api from "./AxiosInterceptor";
const config = require("../appConfig");
const patchApi = config.API_ENPOINT + "/merchant/v1.0";

export const getInformationMerchant = async () => {
  const url = patchApi + "/information-merchant";
  const response = await api.get(url);

    const merchantData = response?.data?.data;
    if (merchantData) {
        localStorage.setItem("informationMerchant", JSON.stringify(merchantData));
    }
    
   return response.data; 
}

export const updateInformationMerchant = async (data: any) => {
  const url = `${patchApi}/update-merchant`;
  const response = await api.put(url, data);
  
  const updatedMerchantData = response?.data?.data;
  if (updatedMerchantData) {
    localStorage.setItem("informationMerchant", JSON.stringify(updatedMerchantData));
  }

  return response.data;
};
