import { takeEvery, call, put } from "redux-saga/effects";
// import { RESPONSE_STAUS_CODE } from '../../../appConstants';
import {
  GET_LIST_GROUP,
  getListGroupFail,
  getListGroupSuccess,
} from "../../actions/Customer/ListGroupAction";
import { getListGroup } from "../../../api/Customer";
import { setLoading } from "../../actions/LoadingAction";

interface Respon {
  code?: number;
  data?: any;
  message?: string;
}

function* fetchListGroup(action: any) {
  try {
    // Bật loading khi bắt đầu gọi API
    yield put(setLoading(true));

    // Gọi API
    const res: Respon = yield call(getListGroup);

    // Thành công, lưu dữ liệu vào Redux
    yield put(getListGroupSuccess(res?.data));
  } catch (error) {
    // Gặp lỗi, cập nhật lỗi vào Redux
    yield put(getListGroupFail(error));
  } finally {
    // Tắt loading khi kết thúc gọi API
    yield put(setLoading(false));
  }
}

export default function* ListGroupSaga() {
  yield takeEvery(GET_LIST_GROUP, fetchListGroup);
}
