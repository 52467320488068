export const ENVIRONMENT = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
  TEST: "test",
};

export const RESPONSE_STAUS_CODE = {
  SUCCESS: { code: 200, name: "success" },
  CREATE: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  INTERNAL_SERVER_ERROR: 500,
};

export const DATE_SELECTOR = [
  { code: 1, name: "7 ngày" },
  { code: 2, name: "14 ngày" },
  { code: 3, name: "30 ngày" },
  { code: 4, name: "3 tháng" },
  { code: 5, name: "6 tháng" },
];

export const DATE_SELECTOR_TRANSACTION = [
  { code: 1, name: "Hôm nay" },
  { code: 2, name: "7 ngày" },
  { code: 3, name: "30 ngày" },
];

export const FILTER_SELECT = [
  { code: 1, name: "Hôm nay", unti: "day" },
  { code: 2, name: "Tuần này", unti: "week" },
  { code: 3, name: "Tháng này", unti: "month" },
];

export const TAB_REPORT = {
  REVENUE: { code: 0, name: "Doanh thu" },
  ORDER: { code: 1, name: "Đơn hàng" },
};

export const CUSTOM_BAR_CHART = [
  { code: "profitAmount", name: "Doanh thu" },
  { code: "lossAmount", name: "Chi phí" },
];

export const STATUS_TOKEN = {
  TOKEN_EXPIRATION: { code: "MER010", message: "Token hết hạn" },
};

export const TIME_SELECTOR = {
  DAY: { code: 1, type: "day" },
  MONTHS: { code: 2, type: "month" },
};

export const CUSTOMER = "customer";
export const DEFAULT_TIME = "day";
export const DEFAULT_TIME_REPORT = "7 ngày";
export const TODAY = "Hôm nay";
