export const GET_LIST_GROUP = "GET_LIST_GROUP";
export const GET_LIST_GROUP_SUCCESS = "GET_LIST_GROUP_SUCCESS";
export const GET_LIST_GROUP_FAIL = "GET_LIST_GROUP_FAIL";

export const getListGroup = (payload: object) => {
  return {
    type: GET_LIST_GROUP,
    payload,
  };
};
export const getListGroupSuccess = (payload: any) => {
  return {
    type: GET_LIST_GROUP_SUCCESS,
    payload,
  };
};
export const getListGroupFail = (payload: any) => {
  return {
    type: GET_LIST_GROUP_FAIL,
    payload,
  };
};
