import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_API_ENPOINT;
const TOKEN_REFRESH_THRESHOLD = 150000; // 2.5 phút
const UNAUTHORIZED_STATUS = 401;

// Tạo instance API chính
const api = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Instance riêng cho refresh token không cần header
const refreshApi = axios.create({
  baseURL: API_ENDPOINT,
});

// Kiểm tra token có cần làm mới không
const shouldRefreshToken = (token: string): boolean => {
  try {
    const tokenPayload = JSON.parse(atob(token.split('.')[1]));
    if (!tokenPayload.exp) return false;

    const expirationTime = tokenPayload.exp * 1000;
    const currentTime = Date.now();
    console.log(expirationTime - currentTime);
    
    return (expirationTime - currentTime) < TOKEN_REFRESH_THRESHOLD;
  } catch {
    return false;
  }
};

// Làm mới access token
const refreshAccessToken = async (): Promise<string> => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) throw new Error('Không có refresh token');

    const { data } = await refreshApi.post("/auth/v1.0/refresh", {
      token: JSON.parse(refreshToken)
    });

    const { accessToken, refreshToken: newRefreshToken } = data.data;

    localStorage.setItem('accessToken', JSON.stringify(accessToken));
    localStorage.setItem('refreshToken', JSON.stringify(newRefreshToken));

    return accessToken;
  } catch (error) {
    handleAuthError();
    throw error;
  }
};

// Xử lý lỗi xác thực
const handleAuthError = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  // window.location.href = "/session-expiration";
};

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    const accessTokenStr = localStorage.getItem('accessToken');
    if (!accessTokenStr) return config;

    let accessToken = JSON.parse(accessTokenStr);

    // Tự động refresh token nếu gần hết hạn
    if (shouldRefreshToken(accessToken)) {
      try {
        accessToken = await refreshAccessToken();
      } catch (error) {
        console.error('Lỗi refresh token:', error);
      }
    }

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Xử lý lỗi 401 và refresh token
    if (error.response?.status === UNAUTHORIZED_STATUS && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();
        
        // Cập nhật token trong header
        api.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        
        // Thử lại request ban đầu
        return api(originalRequest);
      } catch (error) {
        handleAuthError();
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default api;