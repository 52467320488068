// Tabs.tsx
import React, { useEffect, useState } from "react";
import { TabProps, TabsChildProps } from "../../types/Tab";

const TabChild: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>;
};

const TabsChild: React.FC<TabsChildProps> = ({
  children,
  storageKey = "activeTabChill",
  onTabClick,
}) => {
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem(storageKey);
    return savedTab ? parseInt(savedTab, 10) : 0;
  });

  useEffect(() => {
    localStorage.setItem(storageKey, activeTab.toString());
  }, [activeTab, storageKey]);

  useEffect(() => {
    const handleUnload = () => {
      localStorage.removeItem(storageKey);
    };

    window.addEventListener("beforeunload", handleUnload);
    // Trên mobile, sử dụng sự kiện "pagehide" để đảm bảo trạng thái cũng được xóa
    window.addEventListener("pagehide", handleUnload);

    // Cleanup event listeners khi component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      window.removeEventListener("pagehide", handleUnload);
    };
  }, [storageKey]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    onTabClick(index);
  };

  return (
    <div>
      {/* Tab Buttons */}
      <div className="flex item-center justify-start px-3 mb-2">
        {React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) return null;
          return (
            <button
              className={`px-2 py-1 font-bold  ${
                index === activeTab
                  ? "text-white rounded-xl bg-green500"
                  : "text-gray"
              }`}
              onClick={() => handleTabClick(index)}
            >
              {child.props.label}
            </button>
          );
        })}
      </div>

      {/* Tab Content */}
      <div>{React.Children.toArray(children)[activeTab]}</div>
    </div>
  );
};

export { TabsChild, TabChild };
