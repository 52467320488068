import { DataResTopCustomer, ReportAction, ReportActionFail, ReportData, TopCustomerAction } from "../../types/ReportType";

export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";

export const GET_TOP_CUSTOMER = "GET_TOP_CUSTOMER";
export const GET_TOP_CUSTOMER_SUCCESS = "GET_TOP_CUSTOMER_SUCCESS";
export const GET_TOP_CUSTOMER_FAIL = "GET_TOP_CUSTOMER_FAIL";

export const getReport = (payload: ReportAction) => {   
    return {
        type: GET_REPORT,
        payload
    }
}
export const getReportSuccess = (payload: ReportData) => {
    return {
        type: GET_REPORT_SUCCESS,
        payload
    }
}
export const getReportFail = (payload: ReportActionFail) => {
    return {
        type: GET_REPORT_FAIL,
        payload
    }
}

export const getTopCustomer = (payload: TopCustomerAction) => {  
    return {
        type: GET_TOP_CUSTOMER,
        payload
    }
}
export const getTopCustomerSuccess = (payload: DataResTopCustomer) => {
    return {
        type: GET_TOP_CUSTOMER_SUCCESS,
        payload
    }
}
export const getTopCustomerFail = (payload: ReportActionFail) => {
    return {
        type: GET_TOP_CUSTOMER_FAIL,
        payload
    }
}
