import { ReportActions, ReportState } from "../../types/ReportType";
import { 
    GET_REPORT,
    GET_REPORT_FAIL, 
    GET_REPORT_SUCCESS, 
    GET_TOP_CUSTOMER_FAIL, 
    GET_TOP_CUSTOMER_SUCCESS 
} from "../actions/ReportAction";

const initialState: ReportState = {
    data: {
      pnlList: [], 
      totalProfit: 0, 
      totalTransaction: 0, 
      type: ""
    },
    dataTopCustomer: {
      size: 0,
      result: [],
    },
    loading: true
  };
  

const ReportReducer = (state = initialState, action: ReportActions): ReportState => {

    switch (action.type) {
        case GET_REPORT: 
            return {
                ...state,
                loading: true
            }
        case GET_REPORT_SUCCESS:
            return {
                ...state,
                data: action?.payload,
                loading: false
            };
        case GET_TOP_CUSTOMER_SUCCESS:
            return {
                ...state,
                dataTopCustomer: action?.payload,
                loading: false
            }
        case GET_REPORT_FAIL:
        case GET_TOP_CUSTOMER_FAIL:
            return {
                ...state,
            };
        default:
            return state; 
    }
};

export default ReportReducer;