import React from "react";
import { Tab, Tabs } from "../../components/Tabs/Tabs";
import Footer from "../../components/Footer/Footer";
import TransactionPageComponent from "../../components/TransactionPage/TransactionPage";
import StatementPageComponent from "../../components/StatementPageComponent/StatementPageComponent";
const TransactionPage: React.FC = () => {
  return (
    <>
      <div className="p-3">
        <Tabs>
          <Tab label="Giao dịch">
            <TransactionPageComponent />
          </Tab>
          <Tab label="Sao kê thông minh">
            <StatementPageComponent />
          </Tab>
        </Tabs>
      </div>
      <div className="fixed bottom-0 w-full px-3 pt-3 bg-white ">
        <Footer />
      </div>
    </>
  );
};

export default TransactionPage;
