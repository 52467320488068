import React from "react";
import LogoBank from "./../../assets/Images/image11.png";
import { ArrowRed, ArrowGreen } from "../../assets/Icons";

interface CustomerProps {
  name: string;
  nickName?: string;
  stk: string;
  group: string;
  isNickName?: boolean;
  isIcons?: boolean;
  moveAway?: string;
}

const ItemCustomer: React.FC<CustomerProps> = ({
  name,
  nickName,
  stk,
  group,
  isIcons,
  moveAway,
  isNickName,
}) => {
  const admit = (moveAway: string | undefined) => {
    return moveAway === "D" ? <ArrowRed /> : <ArrowGreen />;
  };
  return (
    <>
      <div className="mb-4 flex gap-2 items-start">
        <div className="flex justify-center items-start gap-2 self-stretch pt-1">
          <img src={LogoBank} alt="" />
        </div>
        <div className="flex justify-between item-start pb-4 w-full border-b border-gray100">
          <div>
            <p className="font-bold">{name}</p>
            {isNickName ? <p>{nickName ? `(${nickName})` : null} </p> : null}
            <p className="text-gray flex justify-start items-center gap-1">
              {isIcons ? admit(moveAway) : null} STK:{stk}
            </p>
          </div>
          <div>
            <p className="bg-green200 text-green500 rounded flex justify-center items-center p-1 h-fit">
              {group}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default ItemCustomer;
