import { format, isSameDay } from "date-fns";

export const formatDate = (date: {
  year: string;
  month: string;
  day: string;
}): string => {
  const { year, month, day } = date;
  const dateObj = new Date(Number(year), Number(month) - 1, Number(day)); // month trong JS bắt đầu từ 0
  return format(dateObj, "dd/MM/yyyy");
};

export const formatDateAM = (value: string) => {
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const today = new Date();
  if (isSameDay(date, today)) {
    return format(date, "HH:mm a");
  } else {
    return format(date, "HH:mm dd/MM/yyyy");
  }
};

export const formatDateParams = (value: Date) => {
  return format(value, "yyyy-MM-dd");
};

export const formatDateTime = (value: string) => {
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return "Invalid Date"; // hoặc trả về một chuỗi mặc định
  }
  return format(date, "HH:mm dd/MM/yyyy");
};
