import React, { useEffect, useRef, useState } from "react";
import DatePicker from "./Date";
import { Close, ArrowRight } from "../../assets/Icons";
import { Calendar } from "../../assets/Icons/IconSVG";
import { differenceInDays, parse } from "date-fns";
interface Filter {
  isOpen: boolean;
  onClose: () => void;
  onTimeValue: (value: string) => void;
  minPickDay: boolean;
}

const DateTimePicker: React.FC<Filter> = ({
  minPickDay,
  isOpen,
  onClose,
  onTimeValue,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isNextComponent, setIsNextComponent] = useState<boolean>(true);
  const [isFocusedFrom, setIsFocusedFrom] = useState<boolean>(false);
  const [isFocusedTo, setIsFocusedTo] = useState<boolean>(false);
  const [errors, setErrors] = useState<boolean>(false);
  const [errorsText, setErrorsText] = useState<boolean>(false);
  const inputFromRef = useRef<HTMLInputElement>(null);
  const inputToRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
      setTimeout(() => inputFromRef.current?.focus(), 300);
    } else {
      setTimeout(() => setShowModal(false), 300);
    }
  }, [isOpen]);

  useEffect(() => {
    const selectTime: string = `${startDate}-${endDate}`;
    onTimeValue(selectTime);
  }, [startDate, endDate]);

  if (!showModal && !isOpen) return null;

  const handleStart = (value: string) => {
    setStartDate(value);
  };

  const handleEnd = (value: string) => {
    const date1 = parse(startDate, "dd/MM/yyyy", new Date());
    const date2 = parse(value, "dd/MM/yyyy", new Date());
    const diffDays = differenceInDays(date2, date1);
    if (minPickDay) {
      if (diffDays >= 5) {
        setEndDate(value);
        setErrorsText(false);
      } else {
        setErrorsText(true);

        setEndDate("");
      }
    } else {
      if (diffDays >= 1) {
        setErrors(false);
        setEndDate(value);
      } else {
        setErrors(true);
        setEndDate("");
      }
    }
  };

  const handleSearch = () => {
    if (endDate.trim() === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
      onClose();
    }
  };
  const checkMinPickDay = () => {
    if (minPickDay) return errorsText;
    return errors;
  };
  return (
    <div className="fixed flex items-end justify-center inset-0 z-[100] bg-opacity-50 bg-[#A2A0A8] w-full h-screen">
      <div
        className={`py-[24px] px-[20px] w-screen bg-white rounded rounded-tl-[32px] rounded-tr-[32px] transform transition-transform duration-300 ease-out ${
          isOpen ? "animate-slideUp" : "animate-slideDown"
        }`}
      >
        <div className="relative mb-[24px] ">
          <p className="px-[24px] text-xl font-extrabold text-center">
            Lọc kết quả
          </p>
          <button className="absolute top-1 right-0" onClick={onClose}>
            <Close />
          </button>
        </div>

        <div className="mb-[24px] flex items-center justify-between gap-2">
          <div className="relative">
            <div className="absolute top-3.5 left-3 w-fit">
              <Calendar isActive={isFocusedFrom || startDate !== ""} />
            </div>
            <input
              type="text"
              ref={inputFromRef}
              placeholder="Thời gian"
              value={startDate}
              onFocus={() => {
                setIsFocusedFrom(true);
                setIsNextComponent(true);
              }}
              onBlur={() => {
                setIsFocusedFrom(false);
              }}
              readOnly
              className="p-3 input-calendar rounded-2xl bg-green200 pl-8 focus:outline-none focus:ring focus:ring-green500 "
            />
          </div>
          <div>
            <ArrowRight />
          </div>
          <div className="relative">
            <div className="absolute top-3.5 left-3 w-fit">
              <Calendar isActive={isFocusedTo || endDate !== ""} />
            </div>
            <input
              type="text"
              ref={inputToRef}
              placeholder="Thời gian"
              value={endDate}
              onFocus={() => {
                setIsFocusedTo(true);
                setIsNextComponent(false);
              }}
              onBlur={() => {
                setIsFocusedTo(false);
              }}
              readOnly
              className={`p-3 input-calendar rounded-2xl bg-green200 pl-8 ${
                checkMinPickDay()
                  ? "focus:outline-1 focus:outline-[#DC0105]"
                  : "focus:outline-1 focus:outline-green500"
              } `}
            />
          </div>
        </div>

        {errors ? (
          <p className="text-[#DC0105]">
            Vui lòng chọn ngày kết thúc lớn hơn ngày bắt đầu
          </p>
        ) : null}
        {errorsText ? (
          <p className="text-[#DC0105]">
            Vui lòng chọn ngày kết thúc lớn hơn ngày bắt đầu tối thiểu là 5 ngày
          </p>
        ) : null}
        {startDate && endDate ? (
          <p>
            Từ {startDate} đến {endDate}
          </p>
        ) : null}

        <div className="mb-[24px]">
          <p className="text-lg font-extrabold">Chọn ngày giờ</p>
        </div>

        <div>
          {isNextComponent ? (
            <DatePicker
              isFinal={isNextComponent}
              isDisabled={false}
              onPrev={onClose}
              onValue={handleStart}
              onNext={() => {
                setIsNextComponent(false);
              }}
            />
          ) : (
            <DatePicker
              isDisabled={isDisabled}
              isFinal={isNextComponent}
              onPrev={() => setIsNextComponent(true)}
              onValue={handleEnd}
              onSearch={handleSearch}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DateTimePicker;
