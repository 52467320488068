import {
  FailRespone,
  ListCustomerRes,
  SearchParams,
} from "../../../types/Customer";

export const GET_LIST_CUSTOMER = "GET_LIST_CUSTOMER";
export const GET_LIST_CUSTOMER_SUCCESS = "GET_LIST_CUSTOMER_SUCCESS";
export const GET_LIST_CUSTOMER_FAIL = "GET_LIST_CUSTOMER_FAIL";

export const getListCustomer = (payload: SearchParams) => {
  return {
    type: GET_LIST_CUSTOMER,
    payload,
  };
};
export const getListCustomerSuccess = (payload: ListCustomerRes) => {
  return {
    type: GET_LIST_CUSTOMER_SUCCESS,
    payload,
  };
};
export const getListCustomerFail = (payload: FailRespone) => {
  return {
    type: GET_LIST_CUSTOMER_FAIL,
    payload,
  };
};
