import { createBrowserRouter } from "react-router-dom";
import Onboard from "./pages/Onboard/Onboard";
import StoreSetup from "./pages/StoreSetup/StoreSetup";
import HomePage from "./pages/Home/HomePage";
import CustomerManagement from "./pages/CustomerManagement/CustomerManagement";
import DetailCustomer from "./pages/CustomerManagement/DetailCustomer";
import DetailGroupCustomer from "./pages/CustomerManagement/DetailGroupCustomer";
import InforCustomer from "./pages/InforCustomer/InforCustomer";
import TransactionDetails from "./pages/CustomerManagement/TransactionDetails";
import TermsAndPolicy from "./pages/StoreSetup/TermsAndPolicy";
import StoreInfo from "./pages/Onboard/StoreInfo";
import TransactionPage from "./pages/Transaction/Transaction";
import DetailTransactionPage from "./pages/Transaction/DetailTransactionPage";
import Report from "./pages/Report/Report";
import SessionExpiration from "./components/SessionExpiration/SessionExpiration";
import NoDataFound from "./components/NoDataFound/NoDataFound";

const routes = [
  {
    path: "/home-page",
    element: <HomePage />,
  },
  {
    path: "/",
    element: <Onboard />,
  },
  {
    path: "/store-setup",
    element: <StoreSetup />,
  },
  {
    path: "/terms-policy",
    element: <TermsAndPolicy />,
  },
  {
    path: "/store",
    element: <StoreInfo />,
  },
  {
    path: "/customer",
    element: <CustomerManagement />,
  },
  {
    path: "/detail-customer",
    element: <DetailCustomer />,
  },
  // {
  //   path: "/group-customer",
  //   element: <GroupCustomer />,
  // },
  {
    path: "/detail-ground-customer",
    element: <DetailGroupCustomer />,
  },
  {
    path: "/info-customer",
    element: <InforCustomer />,
  },
  {
    path: "/detail-transaction",
    element: <TransactionDetails />,
  },
  {
    path: "/detail-transaction-page",
    element: <DetailTransactionPage />,
  },
  {
    path: "/transaction",
    element: <TransactionPage />,
  },
  {
    path: "/report",
    element: <Report />,
  },
  {
    path: "/session-expiration",
    element: <SessionExpiration />,
  },
  {
    path: "/no-data-found",
    element: <NoDataFound/>
  }
];

const router = createBrowserRouter(routes);

export default router;
