export const GET_LIST_TRANSACTION = "GET_LIST_TRANSACTION";
export const GET_LIST_TRANSACTION_SUCCESS = "GET_LIST_TRANSACTION_SUCCESS";
export const GET_LIST_TRANSACTION_FAIL = "GET_LIST_TRANSACTION_FAIL";

export const getListTransaction = (payload: object) => {
  return {
    type: GET_LIST_TRANSACTION,
    payload,
  };
};
export const getListTransactionSuccess = (payload: any) => {
  return {
    type: GET_LIST_TRANSACTION_SUCCESS,
    payload,
  };
};
export const getListTransactionFail = (payload: any) => {
  return {
    type: GET_LIST_TRANSACTION_FAIL,
    payload,
  };
};
