import {
  GET_LIST_TRANSACTION_FAIL,
  GET_LIST_TRANSACTION_SUCCESS,
} from "../../actions/Transaction/ListTransactionAction";

interface ListTransactionCustomerState {
  data: any;
  loading: boolean;
}

const initialState: ListTransactionCustomerState = {
  data: [],
  loading: false,
};

const ListTransactionReducer = (
  state = initialState,
  action: any
): ListTransactionCustomerState => {
  switch (action.type) {
    case GET_LIST_TRANSACTION_SUCCESS:
      return {
        ...state,
        data: action?.payload,
      };
    case GET_LIST_TRANSACTION_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default ListTransactionReducer;
