import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DATE_SELECTOR, DEFAULT_TIME_REPORT, TAB_REPORT } from "../../appConstants";
import { convertNumber, formatDateReport } from "../../appCommon";
import CustomBarChart from "../../components/chart/BarChart/CustomBarChart";
import CustomerRanking from "../../components/CustomerRanking/CustomerRanking";
import CustomAreaChart from "../../components/chart/AreaChart/CustomAreaChart";
import CustomDropdown from "../../components/FilterSelect/FilterSelect";
import {
  getReport,
  getTopCustomer,
} from "../../redux/actions/ReportAction";
import {
  ReportLoading,
  ReportSelector,
  TopCustomerSelector,
} from "../../redux/selector/ReportSelector";
import LoadingPage from "../../components/Loading/Loading";
import { DateRange, ReportNoDataProps } from "../../types/ReportType";
import NoDataFoundReport from "../../components/NoDataFound/NoDataFoundReport";

const RevenueReport: React.FC<ReportNoDataProps> = ({ title, value }) => {
  const [timeSelector, setTimeSelector] = useState(DEFAULT_TIME_REPORT);
  const isTabRevenue = value === TAB_REPORT.REVENUE.code;
  const dataReport = useSelector(ReportSelector);
  const isDataReportNotEmpty = Array.isArray(dataReport?.pnlList) && dataReport.pnlList.length > 0;
  const dataTopCustomer = useSelector(TopCustomerSelector); 
  const isLoading = useSelector(ReportLoading); 
  const dispatch = useDispatch();
  
  const fetchReport = () => {
    const reportDateRange:DateRange = formatDateReport(timeSelector, DEFAULT_TIME_REPORT);
    const searchObject = {
      fromDate: reportDateRange?.fromDate,
      toDate: reportDateRange?.toDate,
    };
    dispatch(getReport(searchObject));
  };

  const fetchTopCustomer = () => {
    const searchObject = {
      skip: "1",
    };
    dispatch(getTopCustomer(searchObject));
  };

  useEffect(() => {
    fetchReport();
    fetchTopCustomer();
  }, [dispatch, timeSelector]);

  return (
    <div className="not-italic">
      <LoadingPage isLoading={isLoading}/>
      <header className="mb-4 px-6 flex justify-between">
        <div className="flex flex-col">
          <span className="text-sm text-gray500 mb-1">{title}</span>
          {isTabRevenue ? (
            <span className="flex text-2xl font-bold">
              <span className="text-sm pr-1">đ</span>
              {convertNumber(dataReport?.totalProfit) || 0}
            </span>
          ) : (
            <span className="flex text-2xl font-extrabold">
              {convertNumber(dataReport?.totalTransaction) || 0}
              <span className="text-sm pl-1 pt-2">đơn</span>
            </span>
          )}
        </div>
        <CustomDropdown
          valueSelect={(value: string) => setTimeSelector(value)}
          minDay={true}
          isSelectTime={true}
          filterOption={DATE_SELECTOR}
        />
      </header>
      <main>
        {!isDataReportNotEmpty 
          ? (
           <NoDataFoundReport/>
          ) : (
            <>
              <section className="w-full overflow-x-auto scrollbar scrollbar-thumb-white">
                <CustomAreaChart dataReport={dataReport || []} isTabRevenue={isTabRevenue}/>
              </section>
              <section className="w-ful mt-6 ">
                <h4 className="text-lg px-6 font-extrabold leading-7 mb-5">
                  Doanh thu chi phí
                </h4>
                <div className="w-full overflow-x-auto pl-4 scrollbar scrollbar-thumb-white">
                  <CustomBarChart dataReport={dataReport || []} />
                </div>
              </section>
              <section className="px-2 py-4 mt-4">
                <CustomerRanking dataTopCustomer={dataTopCustomer} />
              </section>
            </>
            )
          }
      </main>
    </div>
  );
};

export default RevenueReport;
