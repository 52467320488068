import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Statistical } from "../../assets/Images/index";
import { useDispatch } from 'react-redux';
import { getAuthenToken } from '../../redux/actions/OnBroadAction';

const StoreInitiation: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const fetchAuthenToken = () => {
        const params = new URLSearchParams(location.search);
        const token = params.get("loginToken");
        console.log(token);
        
        const searchObject = {
            token: token
          }
        dispatch(getAuthenToken(searchObject))
    }

    const handleNext = () => {
        fetchAuthenToken();
        navigate("/store");
    };


  return (
    <div className='bg-white min-h-screen w-full px-6 py-10 flex flex-col justify-between'>
       <div>
            <p className='pt-7 leading-4 text-[#A2A0A8] text-sm font-medium mb-3'>👌 Miniapp mSeller giúp bạn</p>
            <div className='pr-10'>
                <span 
                    className='text-3xl not-italic leading-10 font-extrabold text-[#181C26]'
                >
                    Biết ngay doanh thu hôm nay, tháng này chỉ với 1 chạm.
                </span>
            </div>
            <div className='mt-8 flex justify-center'>
                <img src={Statistical} alt="" />
            </div>
       </div>
        <button 
            className='bg-green500 text-white py-3 rounded-2xl font-bold text-sm leading-6 not-italic'
            onClick={handleNext}
        >
            Bắt đầu
        </button>
    </div>
  )
}

export default StoreInitiation
