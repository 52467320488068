import React, { useCallback, useEffect, useRef, useState } from "react";
import ItemCustomer from "../ItemCustomer/ItemCustomer";
import { Search } from "../../assets/Icons";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { listCustomerSelector } from "../../redux/selector/Customer/CustomerSelector";
import { getListCustomer } from "../../redux/actions/Customer/CustomerAction";
import LoadingPage from "../Loading/Loading";
import { CustomerProp, SearchParams } from "../../types/Customer";
import { LoadingSelector } from "../../redux/selector/LoadingSelector";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { logoBank } from "../../common/logobank";
const Customer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listCustomer = useSelector(listCustomerSelector) || {};

  const isLoading = useSelector(LoadingSelector);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const fetchListCustomer = useCallback(() => {
    const searchObject: SearchParams = {
      skip: page,
      limit: 10,
      searchInput: searchInput,
    };

    dispatch(getListCustomer(searchObject));
  }, [dispatch, searchInput, page]);
  useInfiniteScroll({
    isLoading,
    onLoadMore: () => setPage((prevPage) => prevPage + 1),
    offset: 100, // Optional: customize the offset
  });
  useEffect(() => {
    fetchListCustomer();
  }, [page]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);
    setPage(1);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      fetchListCustomer();
    }, 5000);
  };
  return (
    <>
      <div className="relative mb-4 px-3">
        <input
          type="text"
          placeholder="Tìm kiếm khách hàng"
          value={searchInput}
          onChange={handleSearchChange}
          className="px-4 py-3 bg-green200 w-full rounded-2xl pl-[36px] focus:outline-none"
        />
        <Search className="absolute top-3 left-6" />
      </div>
      <div className="p-4 shadow-custom bg-white ">
        <div className="flex flex-col mb-6">
          <p className="text-lg text-base font-bold">Tất cả khách hàng</p>
          <span className="text-gray">{listCustomer?.size} khách hàng</span>
        </div>
        <div className="h-[calc(100vh-58px - 61px -55px - 64px] overflow-y-scroll">
          {listCustomer?.result?.map((customer: CustomerProp) => (
            <div
              key={customer.customerId}
              onClick={() => {
                navigate("/detail-customer", {
                  state: {
                    id: customer.customerId,
                    accountCustomer: customer.customerAccountNbr,
                    name: customer.customerName,
                    nickName: customer.customerNickname,
                    stk: customer.customerAccountNbr,
                    group: customer.customerGroup,
                    phone: customer.customerPhoneNumber,
                    bank: customer.customerBank,
                    income: customer.customerIncome,
                  },
                });
              }}
            >
              <ItemCustomer
                name={customer.customerName}
                nickName={customer.customerNickname}
                stk={customer.customerAccountNbr}
                group={customer.customerGroup}
                isNickName={true}
                isIcons={false}
              />
            </div>
          ))}
        </div>
      </div>
      <LoadingPage isLoading={isLoading} />
    </>
  );
};

export default Customer;
