import React from "react";
import StoreInitiation from "./StoreInitiation";
import { Shop } from "../../assets/Images/index";

const Onboard: React.FC = () => {
  return (
    <div
      className="bg-green500 w-full min-h-screen flex flex-col justify-between
      items-center relative overflow-y-hidden">
      <div className="flex flex-col flex-grow justify-center items-center text-white not-italic">
        <img className="w-14 h-14" src={Shop} alt="shop" />
        <span className="text-4xl font-bold leading-normal">mSeller</span>
        <div
          className="px-2 py-1 not-italic leading-normal bg-white w-20 h-7 flex 
          justify-center items-center text-center rounded-lg">
          <span className="text-sm font-bold text-green500">MiniApp</span>
        </div>
      </div>
      <div className="text-[#F0F1F5] text-center mt-4 not-italic mb-8">
        <p className="text-sm px-10 text-center leading-5 font-medium ">
          Miniapp mSeller là ứng dụng quản lý dòng tiền thông minh dành cho các
          hộ kinh doanh
        </p>
        <p className="text-xs mt-2 leading-4">© Một sản phẩm của MB Bank</p>
      </div>
      <div className="moving-box absolute w-full">
        <StoreInitiation />
      </div>
    </div>
  );
};

export default Onboard;
