import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ModalLoadingProps {
  isLoading: boolean;
  text?: string; // Optional loading text
}
const LoadingPage: React.FC<ModalLoadingProps> = ({
  isLoading,
  text = "Loading...",
}) => {
  const [isTimeout, setIsTimeout] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setIsTimeout(true);
      }, 5000); 

      return () => clearTimeout(timer); 
    }
  }, [isLoading]);

  if (!isLoading) return null;

  if (isTimeout) {
    navigate("/no-data-found");
  }
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-[#A2A0A8] bg-opacity-50 z-[100]">
      <div className="flex flex-col items-center justify-center p-5 rounded-lg">
        <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-green500"></div>
        <p className="mt-4 text-lg font-semibold text-white">{text}</p>
      </div>
    </div>
  );
};

export default LoadingPage;
