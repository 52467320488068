import { format, parse, subDays, subMonths } from "date-fns";
import { TODAY } from "./appConstants";
type DateRange = { fromDate: string; toDate: string };

export const convertNumber = (value: string | number) => {
  if (value === null || value === undefined || value === "" || value === 0)
    return "";
  const numericValue =
    typeof value === "string" ? value.replace(/,/g, "") : value;
  const numberValue = Number(numericValue);

  return isNaN(numberValue) ? "" : numberValue.toLocaleString("en-US");
};

export const convertStringToNumber = (stringNumber: string) => {
  return parseFloat(stringNumber.replace(/,/g, ""));
};

export const convertToLocaleString = (value: string | number) => {
  return Number(value).toLocaleString();
};

// formatter  tick Oy
export const tickFormatterNumber = (
  value: number,
  setIsBillion: React.Dispatch<React.SetStateAction<boolean>>,
  isBillion: boolean,
  setIsThousand: React.Dispatch<React.SetStateAction<boolean>>,
  setIsMillion: React.Dispatch<React.SetStateAction<boolean>>,
  isMillion: boolean
) => {
  const millions = value / 1000000;
  const thousands = value / 1000;

  if (value === 0) return "0";
  // Hàm làm tròn đến 0.5
  const roundToHalf = (num: number) => {
    return (Math.round(num * 2) / 2).toFixed(1);
  };

  setIsMillion(false);
  setIsBillion(false);
  setIsThousand(false);
  
  if (!isMillion && thousands > 1 && thousands < 1000) {
    setIsThousand(true);
    setIsMillion(false);
    setIsBillion(false);
    return Math.round(thousands).toString();
  }

  if (thousands >= 1000 && thousands < 10000) {
    setIsMillion(true);
    setIsThousand(false);
    setIsBillion(false);
    return roundToHalf(thousands / 1000);
  }

  if (isMillion && thousands < 1000) {
    setIsBillion(false);
    return roundToHalf(thousands / 1000);
  }

  if (millions >= 1000) {
    setIsBillion(true);
    setIsThousand(false);
    return roundToHalf(millions / 1000);
  }
  if (isBillion && millions < 1000) {
    setIsThousand(false);
    return (millions / 1000).toFixed(1);
  }

  return Math.round(millions).toString();
};

//Hiện thị ngày
const getDateRange = (unit: "days" | "months", value: number) => ({
  fromDate: format(
    unit === "days" ? subDays(new Date(), value) : subMonths(new Date(), value),
    "yyyy-MM-dd"
  ),
  toDate: format(subDays(new Date(), 1), "yyyy-MM-dd"),
});

const TODAY_RANGE = {
  fromDate: format(new Date(), "yyyy-MM-dd"),
  toDate: format(new Date(), "yyyy-MM-dd"),
};

const TIME_RANGES = {
  [TODAY]: TODAY_RANGE,
  "7 ngày": () => getDateRange("days", 7),
  "14 ngày": () => getDateRange("days", 14),
  "30 ngày": () => getDateRange("months", 1),
  "3 tháng": () => getDateRange("months", 3),
  "6 tháng": () => getDateRange("months", 6),
} as const;

export const formatDateReport = (timeSelector: string, type: string) => {
  try {
    // Tính ngày thi chọn từ datePacker
    if (timeSelector.includes("-")) {
      const [start, end] = timeSelector.split("-").map(date => 
        format(parse(date.trim(), "dd/MM/yyyy", new Date()), "yyyy-MM-dd")
      );
      return { fromDate: start, toDate: end };
    }

    // Tính ngày khi truyền ngày trực tiếp vào
    const range = TIME_RANGES[timeSelector as keyof typeof TIME_RANGES];
    if (typeof range === 'function') {
      return range(); 
    }

    return range || (type === TODAY ? TODAY_RANGE : getDateRange("days", 7));
  } catch {
    return type === TODAY ? TODAY_RANGE : getDateRange("days", 7);
  }
};