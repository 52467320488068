import { SearchStatementProp } from "../types/Transaction";
import api from "./AxiosInterceptor";
const config = require("../appConfig");
const patchApi = config.API_ENPOINT;

export const getListTranscation = async (params: SearchStatementProp) => {
  const url: string = patchApi + "/statement/v1.0/list-statements";
  const response = await api.get(url, { params });

  return response.data;
};

export const exportTranscation = async (params: object) => {
  const url: string = patchApi + "/statement/v1.0/export";
  const response = await api.get(url, { params });

  return response.data;
};

export const transaction = [
  {
    statementId: "STMT2001",
    amount: "500000",
    currency: "VND",
    note: "Chuyen tien mua hang Chuyen tien mua hangChuyen tien mua hang Chuyen tien mua hang Chuyen tien mua hang",
    sign: "D",
    txnTime: "2024-10-11 12:00:00",
    contractBank: "Vietcombank",
    contractCustomerName: "Nguyen Van A",
    contractAccountNbr: "1234567890",
    customerNickName: "Anh A",
  },
  {
    statementId: "STMT2002",
    amount: "1500000",
    currency: "VND",
    note: "Thanh toan hoa don Chuyen tien mua hang Chuyen tien mua hang Chuyen tien mua hang",
    sign: "C",
    txnTime: "2024-10-12 13:00:00",
    contractBank: "Vietcombank",
    contractCustomerName: "Nguyen Van A",
    contractAccountNbr: "1234567890",
    customerNickName: "Anh A",
  },
  {
    statementId: "STMT2003",
    amount: "2000000",
    currency: "VND",
    note: "Chuyen tien cho ban Chuyen tien mua hang Chuyen tien mua hangChuyen tien mua hangChuyen tien mua hang",
    sign: "D",
    txnTime: "2024-10-13 14:00:00",
    contractBank: "Vietcombank",
    contractCustomerName: "Nguyen Van A",
    contractAccountNbr: "1234567890",
    customerNickName: "Anh A",
  },
  {
    statementId: "STMT2004",
    amount: "750000",
    currency: "VND",
    note: "Chuyen tien tra no Chuyen tien mua hang Chuyen tien mua hang Chuyen tien mua hang",
    sign: "C",
    txnTime: "2024-10-14 15:00:00",
    contractBank: "Vietcombank",
    contractCustomerName: "Nguyen Van A",
    contractAccountNbr: "1234567890",
    customerNickName: "Anh A",
  },
  {
    statementId: "STMT2005",
    amount: "1200000",
    currency: "VND",
    note: "Chuyen khoan thue nha Chuyen tien mua hang Chuyen tien mua hang Chuyen tien mua hangChuyen tien mua hang",
    sign: "D",
    txnTime: "2024-10-15 16:00:00",
    contractBank: "Vietcombank",
    contractCustomerName: "Nguyen Van A",
    contractAccountNbr: "1234567890",
    customerNickName: "Anh A",
  },
];
