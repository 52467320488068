import React from 'react'
import TermsSelection from '../../components/TermsSelection/TermsSelection'
import IconInput from '../../components/IconInput/IconInput';
import { AddressIcon, People, PeopleAudience, PhoneIcon, Profession, ReceiptSparkles, StoreIcon } from '../../assets/Icons/IconSVG';
import BankSelection from '../../components/BankSelection/BankSelection';
import { ChevronDow } from '../../assets/Icons';
import { StoreFormProps } from '../../types/StoreForm';
import ProfessionDropDown from '../../components/Dropdown/ProfessionDropDown';

const StoreForm: React.FC<StoreFormProps> = ({
    handleChange,
    handleChecker,
    handleFormSubmit,
    handleBlur,
    handleKeyDown,
    storeInfo,
    errorMessage,
    isCheck,
    dataMerchant,
    toggleDropdown,
    handleComplete,
    handleCloseDropdow,
    isOpen,
}) => {
    const getIconClassName = (fieldName: keyof typeof storeInfo) => {
        return `absolute top-3.5 left-3 ${storeInfo[fieldName] ? 'fill-green500' : 'fill-gray'} peer-focus:fill-green500`;
    };

  const isDisable = storeInfo?.storeAddress && storeInfo?.storeName && storeInfo?.storePhone;
    
  return (
    <div>
      <form onSubmit={handleFormSubmit} action="">
        <section>
          <IconInput
            placeholder="Tên cửa hàng..."
            icon={<StoreIcon className={getIconClassName('storeName')}/>}
            value={storeInfo.storeName}
            name="storeName"
            type="text"
            maxLength={150}
            onChange={handleChange}
            error={errorMessage?.errorStoreName}
          />
          <IconInput
            placeholder="Địa chỉ của hàng"
            icon={<AddressIcon className={getIconClassName('storeAddress')}/>}
            value={storeInfo.storeAddress}
            name="storeAddress"
            type="text"
            maxLength={250}
            onChange={handleChange}
            error={errorMessage?.errorStoreAddress}
          />
          <IconInput
            placeholder="Số điện thoại..."
            icon={<PhoneIcon className={getIconClassName('storePhone')}/>}
            value={storeInfo.storePhone}
            name="storePhone"
            type="number"
            maxLength={12}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            error={errorMessage?.errorStorePhone}
          />
        </section>

        <section>
          <BankSelection
            handleChecker={handleChecker}
            isCheck={isCheck}
            isStoreSetup={true}
            merchantAccountNbr={dataMerchant?.merchantAccountNbr}
            merchantName={dataMerchant?.merchantName}
          />
        </section>

        <section className="mt-4">
            <IconInput
                placeholder="Doanh thu hàng tháng"
                icon={<ReceiptSparkles className={getIconClassName('storeRevenue')}/>}
                value={storeInfo.storeRevenue}
                name="storeRevenue"
                type="text"
                unit="đ"
                maxLength={12}
                onChange={handleChange}
                onBlur={(e) => handleBlur(e)}
                onKeyDown={handleKeyDown}
            />
            <IconInput
                placeholder="Quy mô khách hàng tháng"
                icon={<PeopleAudience className={getIconClassName('storeEmployeeScale')}/>}
                value={storeInfo.storeEmployeeScale}
                name="storeEmployeeScale"
                type="text"
                maxLength={12}
                onChange={handleChange}
                onBlur={(e) => handleBlur(e)}
                onKeyDown={handleKeyDown}
            />
            <IconInput
                placeholder="Quy mô nhân viên"
                icon={<Profession className={getIconClassName('storeCustomerScale')}/>}
                value={storeInfo.storeCustomerScale}
                name="storeCustomerScale"
                type="text"
                maxLength={8}
                onChange={handleChange}
                onBlur={(e) => handleBlur(e)}
                onKeyDown={handleKeyDown}
            />
            <IconInput
                placeholder="Ngành nghề"
                icon={<People className={getIconClassName('storeIndustry')}/>}
                value={storeInfo?.storeIndustry?.label || ""}
                name="storeIndustry"
                type="text"
                unit={<ChevronDow className="mt-1"/>}
                onClick={toggleDropdown}
                readOnly
            />
          {/* <TermsSelection handleChecker={handleChecker} isConfirm={isConfirm} /> */}
        </section>

        <footer className="flex justify-center mt-4">
          <button
            type="submit"
            disabled={Boolean(!isDisable)}
            className="text-center disabled:bg-[#80ddbe] disabled:opacity-50 bg-green500 text-white py-3 rounded-2xl font-bold 
              text-sm leading-6 not-italic w-full"
          >
            Lưu
          </button>
        </footer>
      </form>
      <div>
        <ProfessionDropDown
          isOpen={isOpen}
          onClose={handleCloseDropdow}
          onComplete={handleComplete}
        />
      </div>
    </div>
  )
}

export default React.memo(StoreForm);
