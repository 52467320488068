import React, { useEffect, useState } from "react";
import { Close } from "../../assets/Icons";

interface Filter {
  isOpen: boolean;
  onClose: () => void;
  onComplete: (option: Option | null) => void;
}

interface Option {
  id: string;
  label: string;
}

const ProfessionDropDown: React.FC<Filter> = ({ isOpen, onClose, onComplete }) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { id: "1", label: "Thời trang" },
    { id: "2", label: "Ăn uống" },
    { id: "3", label: "Du lịch" },
    { id: "4", label: "Nghỉ dương" },
    { id: "5", label: "Văn phòng" },
  ];

  const toggleOption = (option: Option) => {
    if (selectedOption?.id === option.id) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  const handleComplete = () => {
    onComplete(selectedOption);
    onClose();
  };

  return (
    <div
      className={`fixed inset-0 z-50 w-full h-screen flex items-end justify-center bg-opacity-50 bg-[#A2A0A8] transition-opacity duration-300 ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className={`py-[24px] px-[20px] w-screen bg-white rounded rounded-tl-[32px] rounded-tr-[32px] transform transition-transform duration-300 ease-out ${
          isOpen ? "animate-slideUp" : "animate-slideDown"
        }`}
      >
        <div className="relative mb-[24px]">
          <p className="px-[24px] text-xl font-extrabold text-center">Chọn ngành nghề</p>
          <button className="absolute top-1 right-0" onClick={onClose}>
            <Close />
          </button>
        </div>

        <div className="mb-6 flex items-center gap-2 flex-col py-4">
          {options.map((option) => (
            <div
              key={option.id}
              className="flex items-center justify-between w-full border-gray100 border-b cursor-pointer"
              onClick={() => toggleOption(option)}
            >
              <div className="text-base font-semibold pb-4 leading-6">{option.label}</div>
              {selectedOption?.id === option.id && (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <path
                    d="M3.37371 10.6678C3.19025 10.4614 2.87421 10.4428 2.66782 10.6263C2.46143 10.8098 2.44284 
                    11.1258 2.6263 11.3322L6.6263 15.8322C6.81743 16.0472 7.15013 16.057 7.35356 15.8536L17.8536 
                    5.35355C18.0488 5.15829 18.0488 4.84171 17.8536 4.64645C17.6583 4.45118 17.3417 4.45118 17.1465 
                    4.64645L7.02141 14.7715L3.37371 10.6678Z"
                    fill="#181C26"
                  />
                </svg>
              )}
            </div>
          ))}
        </div>

        <div className="flex item-center justify-center gap-[12px] w-full">
          <button
            type="button"
            className="py-[16px] px-[10px] bg-green200 text-green500 font-bold rounded-2xl w-full"
            onClick={onClose}
          >
            Quay lại
          </button>
          <button
            type="button"
            className="py-[16px] px-[10px] bg-green500 text-white font-bold rounded-2xl w-full"
            onClick={handleComplete}
          >
            Hoàn thành
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfessionDropDown;
