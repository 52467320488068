import { HomePageActionTypes, HomePageState } from "../../types/HomeTypes";
import { GET_HOME_PAGE, GET_HOME_PAGE_FAIL, GET_HOME_PAGE_SUCCESS } from "../actions/HomePageAction";

const initialState: HomePageState = {
    data: undefined, 
    loading: true,
    error: null,
};

const HomePageReducer = (state = initialState, action: HomePageActionTypes): HomePageState => {

    switch (action.type) {
        case GET_HOME_PAGE:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_HOME_PAGE_SUCCESS:
            return {
                ...state,
                data: action?.payload,
                loading: false
            };
            case GET_HOME_PAGE_FAIL:
                return {
                    ...state,
                    error: action.payload.detail
                }
        default:
            return state; 
    }
};

export default HomePageReducer;