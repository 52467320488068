import React from "react";
import MenuItem from "./MenuItem";
import {
  ChartIcon,
  HomeIcon,
  ReceiptIcon,
  UserIcon,
} from "../../assets/Icons/IconFooterSVG";

const Footer: React.FC = () => {
  return (
    <div className="fixed bottom-0 start-0 px-6 py-3 w-full not-italic bg-white z-50">
      <ul className="flex items-center justify-between">
        <MenuItem
          path="/home-page"
          label="Trang chủ"
          icon={<HomeIcon isActive={false} />}
        />
        <MenuItem
          path="/report"
          label="Báo cáo"
          icon={<ChartIcon isActive={false} />}
        />
        <MenuItem
          path="/transaction"
          label="Lịch sử"
          icon={<ReceiptIcon isActive={false} />}
        />
        <MenuItem
          path="/customer"
          label="Khách hàng"
          icon={<UserIcon isActive={false} />}
        />
      </ul>
    </div>
  );
};

export default Footer;
