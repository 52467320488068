import React, { useCallback, useEffect, useState } from "react";
import { TabsChild, TabChild } from "../TabChild/TabChild";
import { Download, Search } from "../../assets/Icons";
import ListStatement from "./ListStatement";
import { formatDateReport } from "../../appCommon";
import { DateProps } from "../../types/Transaction";
import { useDispatch, useSelector } from "react-redux";
import { listTransactionSelector } from "../../redux/selector/TransactionSelector/ListTransactionSeletor";
import { getListTransaction } from "../../redux/actions/Transaction/ListTransactionAction";
import LoadingPage from "../Loading/Loading";
import { LoadingSelector } from "../../redux/selector/LoadingSelector";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { TODAY } from "../../appConstants";

const StatementPageComponent: React.FC = () => {
  const [type, setType] = useState<string>("all");
  const [selectDate, setSelectDate] = useState<string>("");
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const dataListTransaction = useSelector(listTransactionSelector) || {};
  const isLoading = useSelector(LoadingSelector);

  // const dataMerchant = useSelector(dataInformationMerchantSelector);
  // useEffect(() => {
  //   dispatch(getInformationMerchant());
  // }, []);
  const fetchCustomerTransaction = useCallback(() => {
    const fromToDate: DateProps = formatDateReport(selectDate, TODAY);
    // if (dataMerchant) {
    const searchObject = {
      customerAccount: "9666699998",
      fromDate: fromToDate.fromDate,
      toDate: fromToDate.toDate,
      skip: 1,
      limit: 10,
      transactionType: type,
      extraction: "Y",
    };
    dispatch(getListTransaction(searchObject));
    // }
  }, [selectDate, type, page]);
  useInfiniteScroll({
    isLoading,
    onLoadMore: () => setPage((prevPage) => prevPage + 1),
    offset: 100, // Optional: customize the offset
  });
  useEffect(() => {
    // if (dataMerchant) {
    fetchCustomerTransaction();
    // }
  }, [selectDate, page, type]);

  const handleTabs = (value: number) => {
    switch (value) {
      case 0:
        setType("all");
        break;
      case 1:
        setType("income");
        break;
      case 2:
        setType("expenditure");
        break;
    }
  };

  const handleSelect = (value: string) => {
    setSelectDate(value);
  };
  return (
    <div>
      <div className="relative mb-4 px-3">
        <input
          type="text"
          placeholder="Tìm kiếm giao dịch"
          className="px-4 py-3 bg-green200 w-full rounded-2xl pl-[36px] focus:outline-none"
        />
        <Search className="absolute top-3 left-6" />
      </div>
      <div className="relative">
        <TabsChild onTabClick={handleTabs}>
          <TabChild label="Tất cả">
            <ListStatement
              transactions={dataListTransaction}
              onSelectValue={handleSelect}
            />
          </TabChild>
          <TabChild label="Tiền vào">
            <ListStatement
              transactions={dataListTransaction}
              onSelectValue={handleSelect}
            />
          </TabChild>
          <TabChild label="Tiền ra">
            <ListStatement
              transactions={dataListTransaction}
              onSelectValue={handleSelect}
            />
          </TabChild>
        </TabsChild>
        <div className="absolute top-[5px] right-0 flex justify-end items-center text-green500 px-3 mb-6">
          <Download /> Tải excel
        </div>
      </div>
      <LoadingPage isLoading={isLoading} />
    </div>
  );
};

export default StatementPageComponent;
