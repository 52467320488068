import React from 'react'
import { MB } from "../../assets/Images/index"
import { convertToLocaleString } from '../../appCommon'
import { CustomerRankingProps } from '../../types/ReportType';

const CustomerRanking: React.FC<CustomerRankingProps> = ({dataTopCustomer}) => {
  const { result } = dataTopCustomer;

  return (
    <div className='not-italic shadow-modalShadow rounded-lg px-4'>
      <h3 className='text-lg font-extrabold leading-7 mb-6'>Top khách hàng</h3>
      <main className='flex flex-col space-y-4'>
      {result?.map((account, index) => (
        <section key={index} className='flex items-center'>
            <div className='pb-4'>
                <img src={MB} alt="" />
            </div>
            <div className='flex justify-between pl-2 w-full border-gray100 border-b pb-4'>
                <div className='flex items-center'>
                    <div className='flex flex-col'>
                        <span className='text-sm leading-5 font-bold capitalize'>{account?.customerName}</span>
                        <span className='text-sm text-gray leading-5 font-normal '>STK:{account?.customerAccountNbr}</span>
                    </div>
                </div>
                <div className='flex flex-col items-end'>
                    <span className='text-sm leading-5 font-bold'>
                        {convertToLocaleString(account?.customerIncome)}đ
                    </span>
                    <span className='text-sm text-gray leading-5 font-normal '>
                        {account?.customerTotalTransaction} đơn hàng
                    </span>
                </div>
            </div>
        </section>
        ))}
      </main>
    </div>
  )
}

export default CustomerRanking
