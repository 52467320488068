import React, { useRef, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { convertNumber } from "../../../appCommon";
import { TIME_SELECTOR } from "../../../appConstants";
import { format } from "date-fns";
import CustomXAxisTick from "./CustomXAxisTick";

// Custom Tooltip Props Interface
interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
  label?: string;
  isMonth?: boolean;
}
interface CustomerReportProps {
  time: string;
  amount: number;
}
interface ArrayCustomerReport {
  customerReport: CustomerReportProps[];
  type: string;
}
// Custom Tooltip Component
const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  isMonth,
}) => {
  const dataTooltip = payload?.[0]?.payload || {};
  const dayName = ["CN", "Th.2", "Th.3", "Th.4", "Th.5", "Th.6", "Th.7"][
    new Date(dataTooltip?.time).getDay()
  ];
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-black rounded-xl p-3">
        <p className="text-xs text-gray100 font-medium leading-4.5">
          {isMonth
            ? `T ${format(new Date(dataTooltip?.time), "MM/yyyy")}`
            : `${dayName} ${format(new Date(dataTooltip?.time), "dd/MM/yyyy")}`}
        </p>
        <p className="text-base text-white font-bold leading-6">
          {convertNumber(dataTooltip?.amount)}đ
        </p>
      </div>
    );
  }
  return null;
};

const CustomChart: React.FC<ArrayCustomerReport> = ({
  customerReport,
  type,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [highlightedDate, setHighlightedDate] = useState<string | null>(null);
  const chartRef = useRef<HTMLDivElement>(null);

  const handleClick = (data: any) => {
    setSelectedIndex(data.activeTooltipIndex);
  };
  const isMonth = type === TIME_SELECTOR.MONTHS.type;

  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!chartRef.current) return;

    const chartWidth = chartRef.current.clientWidth;
    const scrollWidth = chartRef.current.scrollWidth;
    const scrollTriggerOffset = 150;

    const clickPosition = event.clientX;

    if (
      clickPosition < scrollTriggerOffset ||
      clickPosition > chartWidth - scrollTriggerOffset
    ) {
      let newScroll =
        clickPosition + chartRef.current.scrollLeft - chartWidth / 2;

      if (newScroll < 0) newScroll = 0;
      if (newScroll > scrollWidth - chartWidth)
        newScroll = scrollWidth - chartWidth;

      chartRef.current.scrollTo({ left: newScroll, behavior: "smooth" });
    }
  };
  const handleMouseLeave = () => {
    setHighlightedDate(null);
  };
  const chartWidth = Math.max(customerReport?.length * 55, 320);
  return (
    <div
      className="relative overflow-x-auto scrollbar scrollbar-thumb-white"
      ref={chartRef}
      onClick={handleScroll}
      onMouseLeave={handleMouseLeave}
    >
      <ResponsiveContainer height={300} width={chartWidth}>
        <BarChart data={customerReport} barGap={20} onClick={handleClick}>
          <XAxis
            dataKey="time"
            interval={0}
            tick={{ fill: "#8B929C" }}
            axisLine={false}
            tickSize={0}
            tickMargin={16}
            tickFormatter={(value) => {
              if (!value) return "";
              const date = new Date(value);
              return isMonth
                ? `T ${format(date, "MM")}`
                : format(date, "dd/MM");
            }}
            // tick={(props) => (
            //   <CustomXAxisTick
            //     {...props}
            //     highlightedDate={highlightedDate}
            //     isMonth={isMonth}
            //   />
            // )}
          />
          <Tooltip
            content={<CustomTooltip isMonth={isMonth} />}
            cursor={false}
          />
          <Bar
            dataKey="amount"
            radius={[8, 8, 0, 0]}
            barSize={35}
            fill={selectedIndex !== null ? "#EFFBF7" : "#EFFBF7"}
          ></Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomChart;
