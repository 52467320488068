import React, { useEffect, useRef } from 'react'
import { IconLeft } from "../../assets/Icons/index";
import { useNavigate } from 'react-router-dom';

const TermsAndPolicy: React.FC = () => {
  const hasNavigated = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.history.state && window.history.state.idx > 0) {
      hasNavigated.current = true;
    }
  }, []);

  const handleBack = () => { // back lại trang trước
    if (hasNavigated.current) {
      navigate(-1); 
    } else {
      navigate("/store-setup"); 
    }
  };
  return (
    <div className='p-6 not-italic'>
       <header>
          <button onClick={handleBack}>
            <IconLeft/>
          </button>
        </header>
        <main>
            <section className='mt-4 flex flex-col space-y-6'>
                <h2 className='text-2xl font-extrabold leading-8'>Điều khoản và chính sách</h2>
                <p className='text-base font-medium leading-6 text-justify'>
                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered 
                    alteration in some form, by injected humour, or randomised words which don't look even slightly 
                    believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't 
                    anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet 
                    tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. 
                    It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, 
                    to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free 
                    from repetition, injected humour, or non-characteristic words etc.
                </p>
            </section>
        </main>
    </div>
  )
}

export default TermsAndPolicy
