import React, { useEffect, useRef, useState } from "react";
import { IconLeft } from "../../assets/Icons/index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInformationMerchant, updateInformationMerchant } from "../../redux/actions/StoreSetupAction";
import { dataInformationMerchantSelector, loadingInformation } from "../../redux/selector/StoreSetupSelector";
import { validateStoreInfo } from "./StoreInfoValidation";
import { authenTokenSelector } from "../../redux/selector/OnboardSelector";
import { CUSTOMER } from "../../appConstants";
import StoreForm from "./StoreForm";
import { InformationMerchantPayload, Option, TypeStoreInfo } from "../../types/StoreInfor";
import { convertNumber, convertStringToNumber } from "../../appCommon";
import LoadingPage from "../../components/Loading/Loading";

const StoreInfo: React.FC = () => {
  const [isCheck, setIsCheck] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(false);
  let accessToken = localStorage.getItem('accessToken'); 
  const dataMerchant = useSelector(dataInformationMerchantSelector); 
  const authenToken = useSelector(authenTokenSelector);
  const isLoading = useSelector(loadingInformation);
  const [errorMessage, setErrorMessage] = useState({
    errorStoreName: "",
    errorStoreAddress: "",
    errorStorePhone: "",
  });
  const hasNavigated = useRef(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [storeInfo, setStoreInfo] = useState<TypeStoreInfo>({
    storeName: "",
    storeAddress: "",
    storePhone: "",
    storeRevenue: "",
    storeEmployeeScale: "",
    storeCustomerScale: "",
    storeIndustry: null,
  });

  useEffect(() => {
    if (authenToken || accessToken) {
      dispatch(getInformationMerchant());
    }
  }, [authenToken, accessToken]);
  
  useEffect(() => {
    setStoreInfo((prevState) => ({
      ...prevState,
      storeName: dataMerchant?.merchantName || "",
      storeAddress: dataMerchant?.merchantAddress || "",
      storePhone: dataMerchant?.merchantPhone || "",
      storeRevenue: convertNumber(dataMerchant?.monthlyRevenue) || "",
      storeEmployeeScale: convertNumber(dataMerchant?.employeeSize) || "", 
      storeCustomerScale: convertNumber(dataMerchant?.customerSize) || "",
      storeIndustry: dataMerchant?.industry ? { label: dataMerchant.industry, id: "" } : null,
    }));
  }, [dataMerchant]);

  const handleChecker = (e: any) => {
    const { name } = e.target;  
    if (name === CUSTOMER) {
      setIsCheck(!isCheck);
    } else {
      setIsConfirm(!isConfirm);
    }
  };

  useEffect(() => {
    if (window.history.state && window.history.state.idx > 0) {
      hasNavigated.current = true;
    }
  }, []);

  const handleBack = () => { // back lại trang trước
    if (hasNavigated.current) {
      navigate(-1); 
    } else {
      navigate("/transaction"); 
    }
  };

const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value, maxLength } = e.target;
  
  if (value.length <= maxLength) {
    setStoreInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  
};

const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
  const { name, value } = e.target;

  if (value === '') return;
  const convertedValue = convertNumber(value);

  setStoreInfo((prevState) => ({
    ...prevState,
    [name]: convertedValue,
  }));
};

const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
    e.preventDefault(); 
  }
};

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); 
    const formData: InformationMerchantPayload  = {
        name: storeInfo.storeName.trim(),
        address: storeInfo.storeAddress.trim(),
        phone: storeInfo.storePhone,
        accountNbr: dataMerchant.merchantAccountNbr,
        employeeSize: convertStringToNumber(storeInfo.storeEmployeeScale) || 0,
        customerSize: convertStringToNumber(storeInfo.storeCustomerScale)|| 0,
        industry: storeInfo.storeIndustry?.label,
        monthlyRevenue: convertStringToNumber(storeInfo.storeRevenue)|| 0
    };
 
    if(validateStoreInfo(formData, setErrorMessage)) { // cuộn lên khi có lỗi sai
      window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      return;
    }

    dispatch(updateInformationMerchant(formData)); 
    navigate("/home-page");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseDropdow = () => {
    setIsOpen(false);
  }

  const handleComplete = (selectedOption: Option | null) => {
    setStoreInfo((prev) => ({
      ...prev, 
      storeIndustry: selectedOption, 
    }));
    
    setIsOpen(false); 
  };

  return (
    <div className="bg-white min-h-screen px-6 my-4 flex flex-col space-y-4 justify-between" >
      <LoadingPage isLoading={isLoading}/>
      <header>
        <button onClick={handleBack}>
          <IconLeft/>
        </button>
        <div className="mb-7 mt-4">
          <span className="text-2xl font-extrabold not-italic leading-8">
            Thông tin của hàng
          </span>
          <p className="text-sm font-medium text-gray500">
            Bạn vui lòng điền thông tin cửa hàng nhé!
          </p>
        </div>
      </header>
      <section>
        <StoreForm
           storeInfo={storeInfo}
           errorMessage={errorMessage}
           handleChange={handleChange}
           handleBlur={handleBlur}
           handleChecker={handleChecker}
           handleKeyDown={handleKeyDown}
           isCheck={isCheck}
           isConfirm={isConfirm}
           isOpen={isOpen}
           setIsOpen={setIsOpen}
           handleComplete={handleComplete}
           toggleDropdown={toggleDropdown}
           handleCloseDropdow={handleCloseDropdow}
           dataMerchant={dataMerchant}
           handleFormSubmit={handleFormSubmit}
        />
      </section>
    </div>
  );
};

export default StoreInfo;
