import React, { useCallback, useEffect, useState } from "react";
import { Search } from "../../assets/Icons";

import { useLocation, useNavigate } from "react-router-dom";
import CustomDropdown from "../FilterSelect/FilterSelect";
import { DATE_SELECTOR_TRANSACTION, TODAY } from "../../appConstants";
import { convertNumber, formatDateReport } from "../../appCommon";
import ItemTransaction from "../ItemCustomer/ItemTransaction";
import { useDispatch, useSelector } from "react-redux";
import { listTransactionSelector } from "../../redux/selector/TransactionSelector/ListTransactionSeletor";
import { LoadingSelector } from "../../redux/selector/LoadingSelector";
import { DateProps, TransactionProps } from "../../types/Transaction";
import { getListTransaction } from "../../redux/actions/Transaction/ListTransactionAction";
import LoadingPage from "../Loading/Loading";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";

const Transaction: React.FC = () => {
  const [page, setPage] = useState(1);
  const [selectDate, setSelectDate] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    customerAccount,
    contractBank,
    contractCustomerName,
    contractAccountNbr,
  } = location.state || {};
  const dataListTransaction = useSelector(listTransactionSelector) || {};
  const isLoading = useSelector(LoadingSelector);
  const fetchCustomerTransaction = useCallback(() => {
    const fromToDate: DateProps = formatDateReport(selectDate, TODAY);

    const searchObject = {
      customerAccount: customerAccount,
      contractBank: contractBank,
      contractAccountNbr: contractAccountNbr,
      contractCustomerName: contractCustomerName,
      fromDate: fromToDate.fromDate,
      toDate: fromToDate.toDate,
      skip: 1,
      limit: 10,
      transactionType: "all",
      extraction: "N",
    };
    dispatch(getListTransaction(searchObject));
  }, [selectDate, page]);
  useInfiniteScroll({
    isLoading,
    onLoadMore: () => setPage((prevPage) => prevPage + 1),
    offset: 100, // Optional: customize the offset
  });
  useEffect(() => {
    fetchCustomerTransaction();
  }, [page, selectDate]);
  return (
    <>
      <div className="relative">
        <div className="relative mb-4 px-3">
          <input
            type="text"
            placeholder="Tìm kiếm khách hàng"
            className="px-4 py-3 bg-green200 w-full rounded-2xl pl-[36px] focus:outline-none"
          />
          <Search className="absolute top-3 left-6" />
        </div>
        <div className="p-4 ">
          <div className="flex justify-between items-center mb-6">
            <p className="text-xl font-bold">Tất cả giao dịch </p>
            <CustomDropdown
              valueSelect={(value: string) => setSelectDate(value)}
              minDay={false}
              isSelectTime={true}
              filterOption={DATE_SELECTOR_TRANSACTION}
            />
          </div>
          <div>
            {dataListTransaction.map((transaction: TransactionProps) => (
              <div
                key={transaction.statementId}
                onClick={() =>
                  navigate("/detail-transaction-page", {
                    state: {
                      statementId: transaction.statementId,
                      name: transaction.contractCustomerName,
                      stk: transaction.contraAccountNbr,
                      bankName: transaction.contractBank,
                      content: transaction.note,
                      sign: transaction.sign,
                      money: convertNumber(transaction.amount),
                      time: transaction.txnTime,
                    },
                  })
                }
              >
                <ItemTransaction
                  name={transaction.contractCustomerName}
                  nickName={transaction.nickname}
                  stk={transaction.contraAccountNbr}
                  isNickName={false}
                  isIcons={true}
                  moveAway={transaction.sign}
                  money={convertNumber(transaction.amount)}
                  time={transaction.txnTime}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <LoadingPage isLoading={isLoading} />
    </>
  );
};

export default Transaction;
