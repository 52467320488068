import {
  GET_LIST_GROUP_FAIL,
  GET_LIST_GROUP_SUCCESS,
} from "../../actions/Customer/ListGroupAction";

interface ListGroupState {
  data: any;
}

const initialState: ListGroupState = {
  data: [],
};

const ListGroupReducer = (
  state = initialState,
  action: any
): ListGroupState => {
  switch (action.type) {
    case GET_LIST_GROUP_SUCCESS:
      return {
        ...state,
        data: action?.payload,
      };
    case GET_LIST_GROUP_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default ListGroupReducer;
