import React, { useState, useRef, useEffect } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { format } from 'date-fns';
import { CustomActiveDot } from './CustomActiveDot';
import CustomTooltip from './CustomTooltip';
import { TIME_SELECTOR } from '../../../appConstants';
import { CustomAreaChartProps } from '../../../types/CustomChart';
import { tickFormatterNumber } from '../../../appCommon';
import { CustomizedXAxisTick } from './CustomizedXAxisTick';

const CustomAreaChart: React.FC<CustomAreaChartProps> = ({dataReport, isTabRevenue}) => {
  const [activeDotCoords, setActiveDotCoords] = useState<{ x: number; y: number } | null>(null);
  const [isBillion, setIsBillion] = useState(false);
  const [isThousand, setIsThousand] = useState(false);
  const [isMillion, setIsMillion] = useState(false)
  const [highlightedXValue, setHighlightedXValue] = useState<string | null>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const isMonth = dataReport.type === TIME_SELECTOR.MONTHS.type;
    
  const chartHeight = 300;
  const chartWidth = dataReport?.pnlList?.length * 70;

  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => { // cuộn khi vượt quá độ dài 1 màn hình
    if (!chartRef.current) return;

    const { clientWidth, scrollWidth } = chartRef.current;
    const clickPosition = event.clientX;
    const scrollTriggerOffset = 120;

    if (clickPosition < scrollTriggerOffset || clickPosition > clientWidth - scrollTriggerOffset) {
      const newScroll = Math.max(0, Math.min(clickPosition + chartRef.current.scrollLeft - clientWidth / 2, scrollWidth - clientWidth));
      chartRef.current.scrollTo({ left: newScroll, behavior: 'smooth' });
    }
  };

  const handleChartClick = (data: any) => {
    if (data.activeLabel) {
      setHighlightedXValue(data.activeLabel); // Update lại màu Ox
    }
  };

  const renderXAxis = () => (
    <XAxis
      dataKey="txnDate"
      tickMargin={16}
      axisLine={false}
      padding={{ left: 30 }}
      tickSize={0}
      height={40}
      dy={10}
      tickFormatter={value => isMonth
        ? `T ${parseInt(format(new Date(value), 'MM'), 10)}`
        : format(new Date(value), 'dd/MM')}
      tick={({ x, y, payload }) => (
        <CustomizedXAxisTick 
          x={x} 
          y={y} 
          payload={payload}
          isMonth={isMonth}
          highlightedValue={highlightedXValue} 
          setHighlightedValue={setHighlightedXValue} 
        />
      )}
    />
  );

  const handleMouseLeave = () => {
    setActiveDotCoords(null);
    setHighlightedXValue(null); // Đặt lại giá trị khi không click xem
  };
  
  return (
    <div className="relative flex" onMouseLeave={handleMouseLeave}>
      {/* Fixed Y-axis container */}
      <div className="w-14 mr-6 h-full z-10 bg-white">
        <div className="w-[81px] h-[300px] mt-6">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={dataReport?.pnlList} margin={{ top: 40 }} onClick={handleChartClick}>
              <YAxis
                axisLine={false}
                padding={{ bottom: 0, top: 0 }}
                tickSize={0}
                tick={{ fill: '#8B929C' }}
                tickFormatter={(value) => 
                  tickFormatterNumber(value, setIsBillion, isBillion, setIsThousand, setIsMillion, isMillion)}
                label={{
                  value: !isThousand ? (isBillion ? 'Tỷ đồng' : 'Triệu đồng') : "Nghìn đồng",
                  position: 'top',
                  dx: 20,
                  dy: -25,
                  style: { textAnchor: 'middle', fill: '#8B929C', fontSize: 12 },
                }}
              />
              {renderXAxis()}
              <Area 
                type="monotone" 
                dataKey="profitAmount" 
                stroke="transparent" 
                fill="none"
                activeDot={<CustomActiveDot setActiveDotPos={setActiveDotCoords} />} 
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Scrollable chart container */}
      <div 
        ref={chartRef} 
        className="flex-1 overflow-x-auto scrollbar scrollbar-thumb-gray-200 scrollbar-track-gray-100" 
        onClick={handleScroll}
      >
        <div style={{ width: `${chartWidth}px`, minWidth: '100%', marginTop: 24 }}>
          <ResponsiveContainer width="100%" height={chartHeight}>
            <AreaChart 
              data={dataReport?.pnlList} 
              margin={{ top: 40, right: 20, left: 0, bottom: 0 }}
              onClick={handleChartClick} // Thêm sự kiện onClick ở đây
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="rgba(6, 189, 125, 0.25)" />
                  <stop offset="100%" stopColor="rgba(6, 189, 125, 0)" />
                </linearGradient>
              </defs>
              {renderXAxis()}
              <CartesianGrid strokeDasharray="5 5" stroke="#F0F1F5" horizontal={true} vertical={false} />
              <Tooltip 
                content={<CustomTooltip isTabRevenue={isTabRevenue} isMonth={isMonth}/>} 
                cursor={false} position={{ y: activeDotCoords ? activeDotCoords.y - 70 : 0 }}
              />
              {activeDotCoords && (
                <svg className="absolute inset-0 pointer-events-none">
                  <line 
                    x1={activeDotCoords.x} 
                    x2={activeDotCoords.x} 
                    y1={chartHeight - 30} 
                    y2={activeDotCoords.y} 
                    stroke="#22c55e" 
                    strokeWidth={2} 
                    strokeDasharray="4 4" 
                  />
                </svg>
              )}
              <Area 
                type="monotone" 
                dataKey="profitAmount" 
                stroke="#22c55e" 
                fill="url(#colorUv)" 
                activeDot={<CustomActiveDot setActiveDotPos={setActiveDotCoords} />} 
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default CustomAreaChart;