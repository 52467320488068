import {
  GET_REPORT_CUSTOMER_FAIL,
  GET_REPORT_CUSTOMER_SUCCESS,
} from "../../actions/Customer/CustomerReportAction";

interface ListGroupState {
  data: any;
}

const initialState: ListGroupState = {
  data: {},
};

const getCustomerReportReducer = (
  state = initialState,
  action: any
): ListGroupState => {
  switch (action.type) {
    case GET_REPORT_CUSTOMER_SUCCESS:
      return {
        ...state,
        data: action?.payload,
      };
    case GET_REPORT_CUSTOMER_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default getCustomerReportReducer;
