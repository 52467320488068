import { AuthTokenPayload, AuthTokenPayloadFail, AuthTokenPayloadSuccess } from "../../types/OnboardTypes";

export const GET_AUTHEN_TOKEN = "GET_AUTHEN_TOKEN";
export const GET_AUTHEN_TOKEN_SUCCESS = "GET_AUTHEN_TOKEN_SUCCESS";
export const GET_AUTHEN_TOKEN_FAIL = "GET_INFORMATION_MERCHANT_FAIL";

export const getAuthenToken = (payload: AuthTokenPayload) => {  
    return {
        type: GET_AUTHEN_TOKEN,
        payload
    }
}
export const getAuthenTokenSuccess = (payload: AuthTokenPayloadSuccess) => {
    return {
        type: GET_AUTHEN_TOKEN_SUCCESS,
        payload
    }
}
export const getAuthenTokenFail = (payload: AuthTokenPayloadFail) => {
    return {
        type: GET_AUTHEN_TOKEN_FAIL,
        payload
    }
}
