import React, { useCallback, useEffect, useState } from "react";
import FromStatement from "../FromStatement/FromStatement";
import { Download, Search } from "../../assets/Icons";
import CustomDropdown from "../FilterSelect/FilterSelect";
import { DATE_SELECTOR_TRANSACTION, TODAY } from "../../appConstants";
import { convertNumber, formatDateReport } from "../../appCommon";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { DateProps, TransactionProps } from "../../types/Transaction";
import { getListTransaction } from "../../redux/actions/Transaction/ListTransactionAction";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { listTransactionSelector } from "../../redux/selector/TransactionSelector/ListTransactionSeletor";
import { LoadingSelector } from "../../redux/selector/LoadingSelector";

const Statement: React.FC = () => {
  const [page, setPage] = useState(1);
  const [selectDate, setSelectDate] = useState<string>("");
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    customerAccount,
    contractBank,
    contractCustomerName,
    contractAccountNbr,
  } = location.state || {};
  const dataListTransaction = useSelector(listTransactionSelector) || {};
  const isLoading = useSelector(LoadingSelector);
  const fetchCustomerTransaction = useCallback(() => {
    const fromToDate: DateProps = formatDateReport(selectDate, TODAY);

    const searchObject = {
      customerAccount: customerAccount,
      contractBank: contractBank,
      contractAccountNbr: contractAccountNbr,
      contractCustomerName: contractCustomerName,
      fromDate: fromToDate.fromDate,
      toDate: fromToDate.toDate,
      skip: 1,
      limit: 10,
      transactionType: "all",
      extraction: "Y",
    };
    dispatch(getListTransaction(searchObject));
  }, [selectDate, page]);
  useInfiniteScroll({
    isLoading,
    onLoadMore: () => setPage((prevPage) => prevPage + 1),
    offset: 100, // Optional: customize the offset
  });
  useEffect(() => {
    fetchCustomerTransaction();
  }, [page, selectDate]);
  return (
    <>
      <div>
        <div className="relative mb-4 px-3">
          <input
            type="text"
            placeholder="Tìm kiếm khách hàng"
            className="px-4 py-3 bg-green200 w-full rounded-2xl pl-[36px] focus:outline-none"
          />
          <Search className="absolute top-3 left-6" />
        </div>
        <div className="flex justify-end items-center text-green500 px-3 mb-4">
          <Download /> Tải excel
        </div>
        <div className="flex justify-between items-center mb-6 px-3">
          <p className="text-xl font-extrabold">Tất cả sao kê</p>
          <CustomDropdown
            valueSelect={(value: string) => setSelectDate(value)}
            minDay={false}
            isSelectTime={true}
            filterOption={DATE_SELECTOR_TRANSACTION}
          />
        </div>
        <div className="px-3">
          {dataListTransaction?.map((transaction: TransactionProps) => (
            <FromStatement
              key={transaction.statementId}
              moveAway={transaction.sign}
              name={transaction.contractCustomerName}
              stk={transaction.contraAccountNbr}
              MFT={transaction.statementId}
              time={transaction.txnTime}
              content={transaction.note}
              sdt={transaction.entityExtraction?.phone[0]}
              money={convertNumber(transaction.amount)}
              isHidden={true}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Statement;
