import { SearchParams } from "../types/Customer";
import api from "./AxiosInterceptor";
const config = require("../appConfig");
const patchApi = config.API_ENPOINT + "/customer/v1.0";
const patchApiGroup = config.API_ENPOINT;

export const getListCustomer = async (params: SearchParams) => {
  const url: string = patchApi + "/list-customer";
  const response = await api.get(url, { params });

  return response.data;
};

export const getListGroup = async () => {
  const url: string = patchApiGroup + "/group/v1.0/list-groups";
  const response = await api.get(url);

  return response.data;
};

export const getInformationCustomer = async (params: object) => {
  const url: string = patchApi + "/list-customer";
  const response = await api.get(url, { params });

  return response.data;
};

export const getReportCustomer = async (params: object) => {
  const url: string = patchApi + "/income-report";
  const response = await api.get(url, { params });

  return response.data;
};
