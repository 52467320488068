import { GET_AUTHEN_TOKEN_FAIL, GET_AUTHEN_TOKEN_SUCCESS } from "../actions/OnBroadAction";
import {BackAccountState} from "../../types/OnboardTypes"
import { OnboardActionTypes } from "../../types/OnboardTypes";

const initialState: BackAccountState = {
    token: undefined, 
};

const OnboardReducer = (state = initialState, action: OnboardActionTypes): BackAccountState => {
    switch (action.type) {
        case GET_AUTHEN_TOKEN_SUCCESS:
            return {
                ...state,
                token: action.payload,
            };
        case GET_AUTHEN_TOKEN_FAIL:
            return {
                ...state,
            };
        default:
            return state; 
    }
};

export default OnboardReducer;
