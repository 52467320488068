import React from "react";
import LogoBank from "./../../assets/Images/image11.png";
import { ArrowRed, ArrowGreen } from "../../assets/Icons";
import { formatDateAM } from "../../common/date";

interface CustomerProps {
  name: string;
  nickName?: string;
  stk: string;
  isNickName?: boolean;
  isIcons?: boolean;
  moveAway: string;
  money: string;
  time: string;
}

const ItemTransaction: React.FC<CustomerProps> = ({
  name,
  nickName,
  stk,
  isIcons,
  moveAway,
  money,
  time,
  isNickName,
}) => {
  const admit = (moveAway: string | undefined) => {
    return moveAway === "D" ? <ArrowRed /> : <ArrowGreen />;
  };
  const admitPlus = (moveAway: string | undefined) => {
    return moveAway === "D" ? "-" : "+";
  };
  return (
    <>
      <div className="mb-4 flex gap-2 items-start">
        <div className="flex justify-center items-start gap-2 self-stretch pt-1">
          <img src={LogoBank} alt="" />
        </div>
        <div className="flex justify-between item-start pb-4 w-full border-b border-gray100">
          <div>
            <p className="font-bold">{name}</p>
            {isNickName ? <p>{nickName ? `(${nickName})` : null} </p> : null}
            <p className="text-gray flex justify-start items-center gap-1">
              {isIcons ? admit(moveAway) : null} STK:{stk}
            </p>
          </div>
          <div>
            <div className="">
              <p className="flex justify-end items-center p-1 h-fit font-bold">
                {admitPlus(moveAway)} {money}
              </p>
              <p className="p-1 text-end text-xs text-[#A2A0A8]">
                {formatDateAM(time)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ItemTransaction;
